import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import { useFetchAssets } from "../../../../../hooks";
import { AccordionAssetsForATriaName, RootState } from "../../../../rx.core";
import HomeCrypto from "../../components/LoadersPages/HomeCrypto";
import { Accordion } from "./Accordion"; // Import your Accordion component

export const Crypto: React.FC = () => {
  const isLoading = useFetchAssets();

  const fromAssets = useSelector((store: RootState) => store.User?.userAssets);

  const groupedAssets = useMemo(() => {
    const updatedGroupedAssets: {
      [key: string]: AccordionAssetsForATriaName[];
    } = {};
    fromAssets.forEach((asset) => {
      // Define a unique identifier based on asset characteristics
      const assetIdentifier = `${asset?.name}-${asset?.symbol}-${asset?.chainName}-${asset?.tokenAddress}`;

      // Check if this specific asset variant already exists
      const existingAssetIndex = updatedGroupedAssets[asset?.name]?.findIndex(
        (a) =>
          `${a?.name}-${a?.symbol}-${a?.chainName}-${a?.tokenAddress}` ===
          assetIdentifier
      );

      if (
        existingAssetIndex !== undefined &&
        existingAssetIndex > -1 &&
        updatedGroupedAssets &&
        updatedGroupedAssets[asset.name]
      ) {
        const item = updatedGroupedAssets[asset.name]?.[existingAssetIndex];
        if (item) {
          // If found, sum the balances
          item.aabalanceInTokens +=
            asset?.wallet?.type === "AA" ? asset?.balanceInTokens : 0;
          item.eoabalanceInTokens +=
            asset?.wallet?.type === "EOA" ? asset?.balanceInTokens : 0;
          item.eoabalanceInUSD +=
            asset?.wallet?.type === "EOA" ? asset?.balanceInUSD : 0;
          item.aabalanceInUSD +=
            asset?.wallet?.type === "AA" ? asset?.balanceInUSD : 0;
          item.balanceInUsd += asset.balanceInUSD;
        }
      } else {
        // If not found, initialize array if necessary and push new asset
        if (!updatedGroupedAssets[asset.name]) {
          updatedGroupedAssets[asset.name] = [];
        }

        const newAsset: AccordionAssetsForATriaName = {
          name: asset?.name,
          symbol: asset?.symbol,
          logoUrl: asset?.logoUrl,
          chainLogo: asset?.chainLogo,
          chainName: asset?.chainName,
          balanceOfTokensInUnits: asset?.balanceOfTokensInUnits,
          decimals: asset?.decimals,
          aabalanceInTokens:
            asset?.wallet?.type === "AA" ? asset?.balanceInTokens : 0,
          eoabalanceInTokens:
            asset?.wallet?.type === "EOA" || !asset?.wallet?.type
              ? asset?.balanceInTokens
              : 0,
          eoabalanceInUSD:
            asset?.wallet?.type === "EOA" || !asset?.wallet?.type
              ? asset?.balanceInUSD
              : 0,
          aabalanceInUSD:
            asset?.wallet?.type === "AA" ? asset?.balanceInUSD : 0,
          quoteRate: asset?.quoteRate,
          tokenAddress: asset?.tokenAddress,
          percentChangein24hr: asset?.percentChangein24hr,
          isCustomToken: asset?.isCustomToken,
          balanceInUsd: asset?.balanceInUSD,
        };
        console.log(newAsset);
        updatedGroupedAssets[asset.name]?.push(newAsset);
      }
    });

    return Object.keys(updatedGroupedAssets)
      .map((el) => ({
        group: el,
        assets: updatedGroupedAssets[el].sort(
          (a, b) => b.balanceInUsd - a.balanceInUsd
        ),
        balanceInUsd: updatedGroupedAssets[el].reduce(
          (total: number, item) =>
            total +
            parseFloat(String(item?.eoabalanceInUSD ?? 0)) +
            parseFloat(String(item?.aabalanceInUSD ?? 0)),
          0
        ),
        balanceInToken: updatedGroupedAssets[el].reduce(
          (total: any, item: any) =>
            total +
            parseFloat(item?.eoabalanceInTokens + item?.aabalanceInTokens),
          0
        ),
      }))
      .sort((a, b) => b.balanceInUsd - a.balanceInUsd);
  }, [fromAssets]);

  console.log("groupedAssets", fromAssets, groupedAssets);

  return (
    <div className="w-full h-[54vh] rounded-[2.7vh] flex-col justify-start items-center gap-[1.4vh] flex">
      <div className="self-stretch px-[3.4vh] pt-[2vh] rounded-[2.7vh] flex-col justify-center items-center gap-[1.4vh] flex">
        <div className="self-stretch justify-start items-center gap-[2.7vh] inline-flex">
          <div className="justify-start items-center gap-[1.4vh] flex">
            <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-[2.4vh] font-semibold font-montserrat leading-normal">
              Crypto
            </div>
          </div>
        </div>
      </div>
      {isLoading && <HomeCrypto />}
      {!isLoading && fromAssets && (
        <div className="w-full overflow-y-auto mb-[2vh]">
          {/* {Object.keys(groupedAssets)?.length > 0 && ( */}
          <div className="w-full h-full">
            <Accordion groupedAssets={groupedAssets} />
            {/* <Accordion groupedAssets={lowBalanceGroup} /> */}
          </div>
          {/* )} */}
        </div>
      )}
      {!isLoading && !Object.keys(groupedAssets)?.length && (
        <div className="scrollbar-thin w-full scrollbar-thumb-gray-300 scrollbar-track-stone-100 overflow-y-auto flex flex-col items-center justify-center h-[38vh] gap-[1.4vh]">
          <img
            src="./images/nocryptodark.svg"
            className="w-[17.85vh] h-[17.85vh]"
            alt="non-crypto-dark"
          />
          <p className="font-[600] text-[1.4vh] text-fontLightColor dark:text-fontLightColorDark">
            You have no crypto right now
          </p>
        </div>
      )}
    </div>
  );
};
