import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  sendDisconnectMessage,
  updateSenderAvatar,
  updateUserAvatar,
} from "../../../../rx.core";
import { useTriaUser } from "../../contexts";
import { UserAvatar } from "../Containers/user/UserAvatar";
// import logout from '@tria-sdk/core/controllers/auth.controller'

import { useNavigate } from "react-router-dom";

export const UserMenuLite: React.FC = () => {
  const { updateAvatar } = useTriaUser();
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [accessToken, setAccessToken] = useState<string>();

  useEffect(() => {
    const storedData = localStorage.getItem("tria.accessToken");
    if (storedData !== null) {
      setAccessToken(storedData);
    } else {
      console.log("Can't find triaName;");
    }
  }, []);
  useEffect(() => {}, [accessToken]);
  const handleAvatarChangeClick = async () => {
    if (accessToken) {
      try {
        setIsLoading(true);
        const response = await updateAvatar(accessToken);
        if (response?.avatar) {
          dispatch(updateUserAvatar(response));
          dispatch(updateSenderAvatar(response));

          // Notify the parent window that the profile photo has changed
          const message = {
            type: "PROFILE_PHOTO_UPDATED",
            payload: { message: true },
          };
          window.parent.postMessage(message, "*");
        }
      } catch (error) {
        console.error(error);
        const { message } = error as Error;
        return Promise.reject(String(message || error));
      } finally {
        setIsLoading(false);
      }
    }
  };

  const embedMode = localStorage.getItem("embedded");

  const handleSignOut = () => {
    sendDisconnectMessage();
  };

  return (
    <div className="flex flex-col w-[200px] p-2 bg-primaryColor dark:bg-popoverBackgroundDark rounded-lg overflow-hidden">
      <div
        className="flex justify-between rounded-lg p-2 items-center gap-[2vh] self-stretch cursor-pointer hover:bg-hoverColor dark:hover:bg-popoverHoverDark"
        onClick={handleAvatarChangeClick}
      >
        <p className="flex text-start text-xs font-[600] leading-[120%] text-fontPrimaryColor dark:text-fontPrimaryDarkColor ">
          Change Avatar
        </p>

        <UserAvatar width="28px" height="28px" loading={isLoading} />
      </div>

      {embedMode && (
        <div
          className="flex justify-between p-2 rounded-lg items-center gap-[2vh] self-stretch cursor-pointer hover:bg-hoverColor dark:hover:bg-popoverHoverDark"
          onClick={handleSignOut}
        >
          <div className="flex w-full justify-between items-center">
            <p className="flex text-start text-xs font-[600] leading-[120%] text-red-500">
              Sign Out
            </p>
            <img src="/icons/logout-red.svg" height={18} width={18} />
          </div>
        </div>
      )}
    </div>
  );
};
