import { derivePath } from "ed25519-hd-key";
import { mnemonicToSeedSync } from "bip39";
import {
  ComputeBudgetProgram,
  Connection,
  Keypair,
  LAMPORTS_PER_SOL,
  PublicKey,
  SystemProgram,
  Transaction,
  TransactionInstruction,
  VersionedTransaction,
  sendAndConfirmTransaction,
} from "@solana/web3.js";
import { encode, decode } from "bs58";

//@ts-ignore
import * as BufferLayout from "buffer-layout";
import {
  createAssociatedTokenAccountInstruction,
  createTransferInstruction,
  getAccount,
  getAssociatedTokenAddress,
  getMint,
  // getOrCreateAssociatedTokenAccount,
  TOKEN_PROGRAM_ID,
} from "@solana/spl-token";
import {
  FeeResponse,
  SendNftInput,
  SendPayload,
  TxnInstruction,
  TxnResponse,
  CreateAtaIxResponse,
  SolanaArgs,
  Gas,
  ShyftSignResponse,
  ILitCustomAuthSignerSolana,
} from "./types";

import nacl from "tweetnacl";
import naclUtil from "tweetnacl-util";

import {
  customErrorMessage,
  isSignedEncodedTxn,
  isTxnSignature,
  viewInExplorerLink,
} from "./utils";

// import { Network, ShyftSdk } from "@shyft-to/js";
import { ShyftService } from "./services";
import { CHAIN_ERROR } from "@tria-sdk/constants";
import { SOLANA_DATA_VALIDATION, SOLANA_ERROR } from "./utils/errors";

export { Transaction };

export const rpcUrl = {
  mainnet:
    "https://solana-mainnet.g.alchemy.com/v2/TY0C-d-2TTj6GHquKvUdOJ60QthiahUL",
  devnet:
    "https://solana-devnet.g.alchemy.com/v2/joC9v1x0q3Nw7GZ70RGZN6DjwN2LsVPd",
};

export class Solana {
  public connection: Connection;
  devnet?: boolean;
  private rpcUrl: string;
  network: "mainnet-beta" | "devnet";
  chainName: "SOLANA" | "SOLANA-DEVNET";
  isSponsored?: boolean = false;
  isGasAbstracted?: boolean = false;

  private triaFeeDepositAddress?: PublicKey;
  // = new PublicKey(
  //   "DjCsL575RP2A2qvDyav6eQovAPVGzoGdNZvh18nAwLWH"
  // );
  // shyft: ShyftSdk;
  // shyftApiKey: string = "sQ0KAuxnuwXEeqW5";

  shyftService?: ShyftService;
  triaFeePayerAddress: string = "6cQe9dKDiXsSAumxr42y2Gp93LFPDyL9p6kpZ9EL4D9P";
  feePayer?: PublicKey;

  //'https://api.mainnet-beta.solana.com';

  ACCOUNT_LAYOUT = BufferLayout.struct([
    BufferLayout.blob(32, "mint"),
    BufferLayout.blob(32, "owner"),
    BufferLayout.nu64("amount"),
    BufferLayout.blob(93),
  ]);

  constructor({
    chainName,
    devnet,
    // feePayer,
    triaFeeDepositAddress,
    apiUrl,
    accessToken,
    isSponsored,
    isGasAbstracted,
    shyftApiKey,
  }: SolanaArgs = {}) {
    if (devnet || chainName == "SOLANA-DEVNET") {
      this.devnet = true;
      this.rpcUrl = rpcUrl.devnet;
      this.network = "devnet";
      this.chainName = "SOLANA-DEVNET";
    } else {
      this.rpcUrl = rpcUrl.mainnet;
      this.network = "mainnet-beta";
      this.chainName = "SOLANA";
    }
    this.connection = new Connection(this.rpcUrl, {
      commitment: "confirmed",
    });

    this.isSponsored = isSponsored;
    this.isGasAbstracted = isGasAbstracted;

    if (triaFeeDepositAddress) {
      this.triaFeeDepositAddress = new PublicKey(triaFeeDepositAddress);
    }

    if (apiUrl) {
      this.shyftService = new ShyftService({
        apiUrl: apiUrl as string,
        apiKey: shyftApiKey,
        accessToken: accessToken as string,
      });
    }
  }

  async init() {
    if (this.isSponsored || this.isGasAbstracted) {
      console.log("Solana sponsored txn!!");
      if (this.shyftService?.apiKey) {
        const res = await this.shyftService?.getFeePayer();
        if (res?.success) {
          this.feePayer = new PublicKey(res.data.feePayer);
        } else
          throw Error(
            "An error occured in fetching Solana feePayer for the given shyft api key. Please check your shyftApiKey."
          );
      } else {
        this.feePayer = new PublicKey(this.triaFeePayerAddress as string);
      }
    }
  }

  async createWallet(mnemonic: string, derivationPathIndex: number) {
    try {
      // const defaultDerivationPath = `m/44'/501'/0'/0'`;
      const seed = mnemonicToSeedSync(mnemonic);
      const derivedSeed = derivePath(
        `m/44'/501'/${derivationPathIndex}'/0'`,
        seed as unknown as string
      ).key;

      const keyPair = Keypair.fromSeed(derivedSeed);

      return {
        privateKey: encode(keyPair.secretKey), // keyPair.secretKey.toString(),
        address: keyPair.publicKey.toBase58(),
      };
    } catch (err) {
      console.error(`Error in solana createWallet: ${err}`);
      throw err;
    }
  }

  async getBalance(accountAddress: string, tokenAddress?: string) {
    try {
      let balance;
      const publicKey = new PublicKey(accountAddress);
      if (tokenAddress) {
        const tokenPublicKey = new PublicKey(tokenAddress);

        const account = await this.connection.getTokenAccountsByOwner(
          publicKey,
          {
            mint: new PublicKey(tokenAddress),
          }
        );

        const tokenMintAccountInfo = await this.connection.getTokenSupply(
          tokenPublicKey
        );
        const decimals = tokenMintAccountInfo.value?.decimals;
        // console.log('DECIMALS: ', decimals);

        balance =
          account.value.length > 0
            ? this.ACCOUNT_LAYOUT.decode(account.value[0].account.data).amount
            : 0;

        return {
          balanceInUnits: balance,
          decimals,
          balance: balance / 10 ** decimals,
        };
      }

      balance = await this.connection.getBalance(publicKey);
      return {
        balanceInUnits: balance,
        balance: balance / LAMPORTS_PER_SOL,
      };
    } catch (error) {
      console.error(`Error in solana getBalance: ${error}`);
      throw Error(`Error in solana getBalance: ${error}`);
    }
  }

  async getSendFee(
    fromAddress: string,
    recipientAddress: string,
    amount: number,
    tokenAddress?: string
  ): Promise<FeeResponse> {
    try {
      // const senderPubkey = newfee PublicKey(fromAddress);
      const recentBlockhash = await this.connection.getLatestBlockhash();

      const transaction = await this.getSendTxnObject(
        fromAddress,
        recipientAddress,
        amount,
        tokenAddress,
        recentBlockhash
      );
      // transaction.feePayer = senderPubkey;
      const fees = await transaction.getEstimatedFee(this.connection);
      console.log(
        `Estimated ${amount} ${
          tokenAddress ? tokenAddress : "SOL"
        } transfer cost: ${fees} lamports`
      );

      if (fees) {
        const feesInSol = fees / LAMPORTS_PER_SOL;
        return { success: true, fee: { eth: feesInSol?.toString(), usd: "0" } };
      } else
        return {
          success: false,
          message: "Fees returned null, please try again!",
        };
    } catch (err: any) {
      console.error(`Error in solana getSendFee: ${err}`);
      return {
        success: false,
        message: customErrorMessage(err) || CHAIN_ERROR.SEND_TOKEN,
      };
    }
  }

  async getSendTxnObject(
    fromAddress: string,
    recipientAddress: string,
    amount: number,
    tokenAddress?: string | null,
    recentBlockhash?: Readonly<{
      blockhash: string;
      lastValidBlockHeight: number;
    }>
  ): Promise<Transaction> {
    try {
      const recipientPubKey = new PublicKey(recipientAddress);
      const senderPubkey = new PublicKey(fromAddress);

      console.log("feePayer: ", this.feePayer || senderPubkey);

      let transaction = new Transaction({
        feePayer: this.feePayer || senderPubkey,
        ...(recentBlockhash
          ? { recentBlockhash: recentBlockhash.blockhash }
          : {}),
      });

      if (tokenAddress) {
        // Get token mint
        const mint = await getMint(
          this.connection,
          new PublicKey(tokenAddress)
        );

        // Get or create associated token accounts for sender and receiver
        const fromTokenAccount = await getAssociatedTokenAddress(
          mint.address, // mint
          senderPubkey // owner
        );
        const recipientTokenAccount = await getAssociatedTokenAddress(
          mint.address,
          recipientPubKey
        );

        // Add token transfer instructions to transaction
        transaction.add(
          createTransferInstruction(
            fromTokenAccount,
            recipientTokenAccount,
            senderPubkey,
            amount * 10 ** mint.decimals // parse the amount
          )
        );
      } else {
        transaction.add(
          SystemProgram.transfer({
            fromPubkey: senderPubkey,
            toPubkey: recipientPubKey,
            lamports: LAMPORTS_PER_SOL * amount,
          })
        );
      }
      return transaction;
    } catch (err) {
      console.error(`Error in solana getSendTxnObject: ${err}`);
      throw err;
    }
  }

  async createAtaIx({
    mint,
    recipientPubKey,
    payerPubKey,
  }: any): Promise<CreateAtaIxResponse> {
    try {
      let recipientTokenAccountPubKey = await getAssociatedTokenAddress(
        mint.address,
        recipientPubKey
      );

      // console.log({ recipientTokenAccountPubKey });

      const accountInfo = await this.connection.getAccountInfo(
        recipientTokenAccountPubKey
      );

      let recipientTokenAccountIx;
      if (!accountInfo) {
        console.log("Recipient token account does not exist, creating...");
        recipientTokenAccountIx = createAssociatedTokenAccountInstruction(
          payerPubKey,
          recipientTokenAccountPubKey,
          recipientPubKey,
          mint.address
        );
      }

      return {
        publicKey: recipientTokenAccountPubKey,
        txnIx: recipientTokenAccountIx,
      };
    } catch (err) {
      console.error(`Error in solana createAtaIx: ${err}`);
      throw err;
    }
  }

  private async isNetworkCongested(): Promise<boolean> {
    const samples = await this.connection.getRecentPerformanceSamples(1);
    if (samples && samples.length > 0) {
      const slotTime = samples[0].numTransactions / samples[0].numSlots;
      // Assume network is congested if transactions per slot exceed a threshold (e.g., 5)
      return slotTime > 5;
    }
    return false;
  }

  async signAndSendTransaction({
    privateKey,
    signer,
    transaction,
    sender,
    gas,
  }: {
    privateKey?: string;
    signer?: ILitCustomAuthSignerSolana;
    sender?: Keypair;
    transaction: Transaction;
    gas?: Gas;
  }): Promise<TxnResponse> {
    try {
      const recentBlockhash = await this.connection.getLatestBlockhash();
      let senderPubKey;
      if (!sender && privateKey) {
        sender = this.getPayer(privateKey);
        senderPubKey = sender.publicKey;
      } else if (signer) {
        senderPubKey = new PublicKey(await signer?.getAddress());
      } else {
        throw Error(SOLANA_DATA_VALIDATION.SIGNER_PRIVATE_KEY_UNDEFINED);
      }

      const feePayer = this.feePayer || senderPubKey;
      console.log("feePayer:", feePayer.toBase58());

      // Create a new transaction
      let txn = new Transaction({
        ...(recentBlockhash
          ? { recentBlockhash: recentBlockhash.blockhash }
          : {}),
        feePayer,
      });
      txn.add(transaction);
      txn.feePayer = feePayer;

      // const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
      //   units: 1000000,
      // });

      // Check network congestion
      const isCongested = await this.isNetworkCongested();

      console.info("solana network congested: ", isCongested);

      // Set the priority fee dynamically based on congestion
      const priorityFeeMicroLamports = isCongested ? 100 : 10; // Adjust values as needed
      const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
        microLamports: priorityFeeMicroLamports,
      });

      txn
        // .add(modifyComputeUnits)
        .add(addPriorityFee);

      if (this.triaFeeDepositAddress && gas && this.isGasAbstracted) {
        console.log(
          `Cutting the platform fee of ${gas.token?.amount} ${gas.token?.name}`
        );
        // && some platform fee percent > 0
        txn = await this.transferToken({
          transaction: txn,
          senderPubKey: senderPubKey,
          recipientPubKey: this.triaFeeDepositAddress,
          payerPubKey: this.feePayer || senderPubKey,
          amount: gas.token?.amount as number, // gasAmount,
          tokenAddress: gas.token?.address as string, // gas token address
        });
      }

      const serializedTransaction = txn
        .serialize({
          requireAllSignatures: false,
          verifySignatures: false,
        })
        .toString("base64");

      let txnSignature;
      if (this.feePayer) {
        console.log(
          "Sponsored transaction: Shyft feePayer",
          this.feePayer.toBase58()
        );
        let signedEncodedTxn: string;
        if (sender) {
          txn.partialSign(sender);
          signedEncodedTxn = txn
            .serialize({ requireAllSignatures: false })
            .toString("base64");
        } else {
          console.log("Solana signer.signTransaction");
          // Sign with signer
          signedEncodedTxn = await (
            signer as ILitCustomAuthSignerSolana
          ).partialSign({
            chain: this.network,
            serializedTransaction,
          });
          console.log({ signedEncodedTxn });
        }

        console.log("Signing on shyft: ", this.chainName, signedEncodedTxn);
        const res = (await this.shyftService?.signViaShyft({
          chainName: this.chainName,
          encodedTransaction: signedEncodedTxn,
        })) as ShyftSignResponse;
        console.log("shyft res: ", res);
        if (!res?.success) {
          console.error(`Failed signViaShyft: ${res}`);
          throw Error(SOLANA_ERROR.SHYFT_SIGN_FAILED);
        }
        txnSignature = res.data.txnSignature;
      } else {
        // Working
        console.log("Not sponsored solana txn");
        if (sender) {
          txnSignature = await this.connection.sendTransaction(txn, [sender]);
        } else {
          // Signer will exist
          console.log("Solana signer.signTransaction and broadcast");

          const txnSignatureOrSignedEncodedTxn = await (
            signer as ILitCustomAuthSignerSolana
          ).signTransaction(
            { chain: this.network, serializedTransaction },
            {
              broadcast: true, // not reliable
            }
          );

          console.log({ txnSignatureOrSignedEncodedTxn });

          if (isTxnSignature(txnSignatureOrSignedEncodedTxn)) {
            console.log("✅Valid txnSignature.");
            txnSignature = txnSignatureOrSignedEncodedTxn;
          } else {
            throw Error(`Lit was unable to broadcast transaction.`);
            // if (isSignedEncodedTxn(txnSignatureOrSignedEncodedTxn)) {
            //   console.log("⏳Valid signedEncodedTxn.");
            //   txnSignature = await this.connection.sendRawTransaction(
            //     Buffer.from(txnSignatureOrSignedEncodedTxn, "base64")
            //   );

            //   console.log("after sendEncodedTransaction", txnSignature);
            // } else {
            //   throw Error(
            //     `Lit response is niether a valid solana txnSignature, nor a valid signed encoded solana txn: ${txnSignatureOrSignedEncodedTxn}`
            //   );
            // }
          }
        }
      }

      return this.getTxnResponse(txnSignature);
    } catch (err: any) {
      console.error(`Error in signAndSendTransaction: ${err}`);
      return {
        success: false,
        message: customErrorMessage(err) || SOLANA_ERROR.SIGN_AND_SEND_TXN,
      };
    }
  }

  async estimateSolBalanceChange(transaction: Transaction) {
    try {
      const txn = await this.connection.simulateTransaction(
        transaction,
        undefined,
        true
      );
      return txn.value.unitsConsumed;
    } catch (err) {
      console.error(`Error in estimateSolBalanceChange: ${err}`);
      throw err;
    }
  }

  async getEstimatedFee(transaction: Transaction): Promise<FeeResponse> {
    const fees = await transaction.getEstimatedFee(this.connection);

    if (fees) {
      const feesInSol = fees / LAMPORTS_PER_SOL;
      return { success: true, fee: { eth: feesInSol?.toString(), usd: "0" } };
    } else
      return {
        success: false,
        message: SOLANA_ERROR.FEES_NULL,
      };
  }

  getTxnInstructions(transaction: Transaction): TxnInstruction[] {
    /** For Sol balance change */
    // const txn = await this.connection.simulateTransaction(
    //   transaction,
    //   undefined,
    //   true
    // );
    // console.log("Simulate: ", txn.value);
    // console.log("Accounts: ", txn.value.accounts);

    let instructions: TxnInstruction[] = [];

    // console.log("Instructions: ");

    transaction.instructions.forEach((itx, index) => {
      // console.log(`Instruction ${index + 1}`);
      // console.log("Keys:");
      // itx.keys.forEach((key) => {
      //   console.log(
      //     `Public Key: ${key.pubkey.toBase58()}, Signer: ${
      //       key.isSigner
      //     }, Writable: ${key.isWritable}`
      //   );
      // });

      const programId = itx.programId.toBase58();
      const data = itx.data.toString("hex");

      instructions.push({ name: "Unknown", programId, data });
    });
    return instructions;
  }

  async send({
    signer,
    privateKey,
    recipientAddress,
    amount,
    tokenAddress,
    gas,
  }: SendPayload): Promise<TxnResponse> {
    console.log("send:", {
      signer,
      privateKey,
      recipientAddress,
      amount,
      tokenAddress,
      gas,
    });
    try {
      let sender;
      let senderPubKey;
      if (privateKey) {
        sender = this.getPayer(privateKey);
        senderPubKey = sender.publicKey;
      } else if (signer) {
        senderPubKey = new PublicKey(await signer.getAddress());
      } else throw Error(SOLANA_DATA_VALIDATION.SIGNER_PRIVATE_KEY_UNDEFINED);

      const payerPubKey = this.feePayer || senderPubKey;

      let transaction;
      // if (!tokenAddress) {
      //   transaction = await this.getSendTxnObject(
      //     fromAddress as string,
      //     recipientAddress,
      //     amount,
      //     tokenAddress as string | null
      //   );
      // } else {
      // Error with getSendTxnObject
      // failed to send transaction: Transaction simulation failed: Error processing Instruction 0: invalid account data for instruction

      // Create a new transaction
      transaction = new Transaction();

      const recipientPubKey = new PublicKey(recipientAddress);

      transaction = await this.transferToken({
        transaction,
        senderPubKey,
        recipientPubKey,
        payerPubKey,
        tokenAddress: tokenAddress as string,
        amount,
      });
      // }

      const res = await this.signAndSendTransaction({
        signer,
        sender,
        transaction,
        gas,
      });
      return res;

      // const tx = await this.connection.getParsedTransaction(txnSignature); // .getTransaction(signature);
    } catch (err: any) {
      console.error(`Error in solana send: ${err}`);
      return {
        success: false,
        message: customErrorMessage(err) || CHAIN_ERROR.SEND_TOKEN,
      };
    }
  }

  async transferToken({
    transaction,
    senderPubKey,
    recipientPubKey,
    payerPubKey,
    amount,
    tokenAddress,
  }: {
    transaction: Transaction;
    senderPubKey: PublicKey;
    recipientPubKey: PublicKey;
    payerPubKey: PublicKey;
    amount: number;
    tokenAddress?: string;
  }): Promise<Transaction> {
    try {
      if (tokenAddress) {
        const mint = await getMint(
          this.connection,
          new PublicKey(tokenAddress)
        );
        // Get the token account of the from address, and if it does not exist, create it
        // const fromTokenAccount = await getOrCreateAssociatedTokenAccount(
        //   this.connection,
        //   sender, // payer
        //   mint.address, // mintx
        //   sender.publicKey // owner
        // );

        // Get the token account of the from address, and if it does not exist, create it
        const fromTokenAccountPubKey = await getAssociatedTokenAddress(
          mint.address,
          senderPubKey
        );

        const {
          publicKey: recipientTokenAccountPubKey,
          txnIx: createRecipientTokenAccountTx,
        } = await this.createAtaIx({ mint, recipientPubKey, payerPubKey });

        if (createRecipientTokenAccountTx) {
          console.log("createRecipientTokenAccountTx");
          transaction.add(createRecipientTokenAccountTx);
        }

        const transferTokensIx = createTransferInstruction(
          fromTokenAccountPubKey,
          recipientTokenAccountPubKey,
          senderPubKey,
          amount * 10 ** mint.decimals // parse the amount
        );

        transaction.add(transferTokensIx);
      } else {
        transaction.add(
          SystemProgram.transfer({
            fromPubkey: senderPubKey,
            toPubkey: recipientPubKey,
            lamports: LAMPORTS_PER_SOL * amount,
          })
        );
      }

      return transaction;
    } catch (err) {
      console.error(`Error in solana transferToken: ${err}`);
      throw err;
    }
  }

  async getSendNftFee(
    fromAddress: string,
    recipientAddress: string,
    tokenAddress: string
  ): Promise<FeeResponse> {
    try {
      // const senderPubkey = new PublicKey(fromAddress);
      const recentBlockhash = await this.connection.getLatestBlockhash();

      const transaction = await this.getSendNftTxnObject(
        fromAddress,
        recipientAddress,
        tokenAddress,
        recentBlockhash
      );

      // transaction.feePayer = senderPubkey;
      const fees = await transaction.getEstimatedFee(this.connection);

      if (fees) {
        const feesInSol = fees / LAMPORTS_PER_SOL;
        return { success: true, fee: { eth: feesInSol?.toString(), usd: "0" } };
      } else {
        return {
          success: false,
          message: SOLANA_ERROR.FEES_NULL,
        };
      }
    } catch (err) {
      console.error(`Error in solana getSendNftFee: ${err}`);
      return {
        success: false,
        message: CHAIN_ERROR.SEND_NFT,
      };
    }
  }

  async getSendNftTxnObject(
    fromAddress: string,
    recipientAddress: string,
    tokenAddress: string,
    recentBlockhash?: Readonly<{
      blockhash: string;
      lastValidBlockHeight: number;
    }>
  ): Promise<Transaction> {
    try {
      const senderPubkey = new PublicKey(fromAddress);
      const receiverPubKey = new PublicKey(recipientAddress);
      const mintPubKey = new PublicKey(tokenAddress);

      let transaction = new Transaction({
        ...(recentBlockhash
          ? { recentBlockhash: recentBlockhash.blockhash }
          : {}),
        feePayer: this.feePayer || senderPubkey,
      });

      const senderTokenAccount = await getAssociatedTokenAddress(
        mintPubKey,
        senderPubkey
      );
      const receiverTokenAccount = await getAssociatedTokenAddress(
        mintPubKey,
        receiverPubKey
      );

      transaction.add(
        createTransferInstruction(
          senderTokenAccount,
          receiverTokenAccount,
          senderPubkey,
          1, // Amount is 1 because NFTs are unique
          []
        )
      );

      return transaction;
    } catch (err) {
      console.error(`Error in solana getSendNftTxnObject: ${err}`);
      throw err;
    }
  }

  async sendNft({
    signer,
    privateKey,
    recipientAddress,
    tokenAddress,
    gas,
  }: SendNftInput): Promise<TxnResponse> {
    try {
      console.log(`Sending NFT ${tokenAddress} to ${recipientAddress}`);
      // Create a keypair for the sender from the secret key
      let sender, senderPubKey;

      if (privateKey) {
        sender = this.getPayer(privateKey);
        senderPubKey = sender.publicKey;
      } else if (signer) {
        senderPubKey = new PublicKey(await signer.getAddress());
      } else {
        throw Error(SOLANA_DATA_VALIDATION.SIGNER_PRIVATE_KEY_UNDEFINED);
      }

      // Convert receiver's public key string to a PublicKey object
      const receiverPublicKey = new PublicKey(recipientAddress);

      // Convert mint address string to a PublicKey object
      const mintPublicKey = new PublicKey(tokenAddress);

      console.log("Fetching sender token account...");
      // Find the sender's token account for the NFT
      const senderTokenAccount = await getAssociatedTokenAddress(
        mintPublicKey,
        senderPubKey
      );

      console.log("Fetching receiver token account...");
      // Find the receiver's associated token address
      const receiverTokenAccount = await getAssociatedTokenAddress(
        // this.connection,
        // sender,
        mintPublicKey,
        receiverPublicKey
        // undefined
        // 'processed'
        // { maxRetries: 5 }
      );

      // const modifyComputeUnits = ComputeBudgetProgram.setComputeUnitLimit({
      //   units: 1000000,
      // });

      // const addPriorityFee = ComputeBudgetProgram.setComputeUnitPrice({
      //   microLamports: 10,
      // });

      let transaction = new Transaction();
      // .add(modifyComputeUnits)
      // .add(addPriorityFee);

      const exists = await this.checkIfTokenAccountExists(receiverTokenAccount);

      if (!exists) {
        console.log("Create Receiver token account!");

        transaction.add(
          createAssociatedTokenAccountInstruction(
            senderPubKey,
            receiverTokenAccount,
            receiverPublicKey,
            mintPublicKey
          )
        );
      }

      // Create itx and add a transaction to transfer the NFT
      transaction.add(
        createTransferInstruction(
          senderTokenAccount,
          receiverTokenAccount,
          senderPubKey,
          1 // Amount is 1 because NFTs are unique
        )
      );

      const res = await this.signAndSendTransaction({
        signer,
        sender,
        transaction,
        gas,
      });
      return res;
    } catch (err: any) {
      console.error(`Error in solana sendNft: ${err}`);
      return {
        success: false,
        message: customErrorMessage(err) || CHAIN_ERROR.SEND_NFT,
      };
    }
  }

  getPayer(privateKey: string): Keypair {
    try {
      if (!privateKey)
        throw Error(SOLANA_DATA_VALIDATION.PRIVATE_KEY_UNDEFINED);
      let secretKey;
      if (privateKey.split(",").length > 1) {
        secretKey = new Uint8Array(privateKey.split(",") as any);
      } else {
        secretKey = decode(privateKey);
      }

      // fetching the public key from the secret key
      const payer = Keypair.fromSecretKey(secretKey, {
        skipValidation: false,
      });

      return payer;
    } catch (err) {
      console.error(`Error in getPayer: ${err}`);
      throw err;
    }
  }

  private getTxnResponse(txnSignature: string): TxnResponse {
    console.log({ txnSignature });
    return {
      success: true,
      data: {
        txnId: txnSignature,
        viewInExplorer: viewInExplorerLink(txnSignature, this.devnet),
        wait: async () => {
          console.log(
            `Waiting for ${viewInExplorerLink(txnSignature, this.devnet)}`
          );
          await this.waitForTransaction(txnSignature);
        },
      },
    };
  }

  // https://solanacookbook.com/references/keypairs-and-wallets.html#how-to-sign-and-verify-messages-with-wallets

  async signMessage({
    signer,
    privateKey,
    message,
  }: {
    signer?: ILitCustomAuthSignerSolana;
    privateKey?: string;
    message: string;
  }): Promise<string> {
    try {
      if (privateKey) {
        const messageBytes = naclUtil.decodeUTF8(message);
        const signature = nacl.sign.detached(messageBytes, decode(privateKey));
        return Buffer.from(signature).toString("hex");
      } else if (signer) {
        return await signer.signMessage(message);
      } else {
        throw Error(SOLANA_DATA_VALIDATION.SIGNER_PRIVATE_KEY_UNDEFINED);
      }
    } catch (err) {
      console.error(`Error in solana signMessage: ${err}`);
      throw err;
    }
  }

  async verifySignature(
    signature: string,
    message: string,
    address: string
  ): Promise<boolean> {
    try {
      const messageBytes = naclUtil.decodeUTF8(message);
      const publicKeyBytes = decode(address);
      const result = nacl.sign.detached.verify(
        messageBytes,
        Buffer.from(signature, "hex"),
        publicKeyBytes
      );
      return result;
    } catch (err) {
      console.error(`Error in solana verifySignature: ${err}`);
      throw err;
    }
  }

  async waitForTransaction(
    txnId: string,
    confirmations?: number | undefined,
    _timeout?: number | undefined
  ): Promise<boolean> {
    try {
      if (confirmations && confirmations > 10) {
        await this.connection.confirmTransaction(txnId, "finalized");
      } else {
        await this.connection.confirmTransaction(txnId, "confirmed");
      }
      return true;
    } catch (err) {
      console.error(`Error in solana waitForTransaction: ${err}`);
      throw err;
    }
  }

  async checkIfTokenAccountExists(receiverTokenAccount: PublicKey) {
    // Check if the receiver's token account exists
    try {
      await getAccount(
        this.connection,
        receiverTokenAccount,
        "confirmed",
        TOKEN_PROGRAM_ID
      );

      return true;
    } catch (thrownObject) {
      const error = thrownObject as Error;
      // error.message is am empty string
      if (error.name === "TokenAccountNotFoundError") {
        return false;
      }
      console.error(`Error in checkIfTokenAccountExists: ${error}`);
      throw error;
    }
  }
}
