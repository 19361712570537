import { useCallback, useEffect, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState, updateUserActivity } from "../package/rx.core";

const events = [
  "mousedown",
  "click",
  "mouseup",
  "focus",
  "keyup",
  "keydown",
  "mousemove",
  "touchstart",
  "touchmove",
];

export function useDetectInactivity({
  timeout = 60000,
}: { timeout?: number } = {}) {
  const inactivityTimer = useRef<number | null>(null);
  const userInactive = useSelector(
    (state: RootState) => state.User.isUserInactive
  );
  const dispatch = useDispatch();

  const setActive = useCallback(() => {
    console.log("user active");
    dispatch(updateUserActivity(false));
  }, [dispatch]);

  const setInactive = useCallback(() => {
    console.log("user inactive");
    dispatch(updateUserActivity(true));
  }, [dispatch]);

  const resetInactivityTimer = useCallback(() => {
    if (inactivityTimer.current) {
      window.clearTimeout(inactivityTimer.current);
    }
    inactivityTimer.current = window.setTimeout(setInactive, timeout);
  }, [setInactive, timeout]);

  const onActivity = useCallback(() => {
    if (userInactive) {
      setActive();
    }
    resetInactivityTimer();
  }, [userInactive, setActive, resetInactivityTimer]);

  useEffect(() => {
    events.forEach((event) => {
      window.addEventListener(event, onActivity);
    });

    resetInactivityTimer();

    return () => {
      events.forEach((event) => {
        window.removeEventListener(event, onActivity);
      });
      if (inactivityTimer.current) {
        window.clearTimeout(inactivityTimer.current);
      }
    };
  }, [onActivity, resetInactivityTimer]);

  return userInactive;
}
// import { useCallback, useEffect, useRef } from "react";
// import { useDispatch, useSelector } from "react-redux";
// import { RootState, updateUserActivity } from "../package/rx.core";

// var evnts = [
//   "mousedown",
//   "click",
//   "mouseup",
//   "focus",
//   "blur",
//   "keyup",
//   "keydown",
//   "keypressed",
// ];

// export function useDetectInactivity({
//   timeout = 60000,
// }: { timeout?: number } = {}) {
//   const inactivityTimer = useRef<number | null>(null);

//   const userInactive = useSelector(
//     (state: RootState) => state.User.isUserInactive
//   );
//   const dispatch = useDispatch();

//   const attachEvents = useCallback((cb: () => void) => {
//     evnts.forEach((event) => {
//       window.addEventListener(event, cb);
//     });
//   }, []);

//   const removeEvents = useCallback((cb: () => void) => {
//     evnts.forEach((event) => {
//       window.removeEventListener(event, cb);
//     });
//   }, []);

//   const onInactivity = useCallback(() => {
//     console.log("user inactive again");
//     dispatch(updateUserActivity(true));
//   }, [dispatch]);

//   const resetInactivityTimer = useCallback(() => {
//     if (inactivityTimer.current) {
//       window.clearTimeout(inactivityTimer.current);
//     }
//     inactivityTimer.current = window.setTimeout(onInactivity, timeout);
//   }, [onInactivity, timeout]);

//   const onActivity = useCallback(() => {
//     if (userInactive) {
//       console.log("user active again");
//       dispatch(updateUserActivity(false));
//       resetInactivityTimer();
//     }
//   }, [dispatch, resetInactivityTimer, userInactive]);

//   useEffect(() => {
//     onActivity();
//     console.log("attaching events");
//     attachEvents(onActivity);
//     return () => {
//       if (inactivityTimer.current) {
//         clearTimeout(inactivityTimer.current);
//       }
//       removeEvents(resetInactivityTimer);
//     };
//   }, [
//     timeout,
//     dispatch,
//     attachEvents,
//     userInactive,
//     removeEvents,
//     onInactivity,
//     onActivity,
//     resetInactivityTimer,
//   ]);
// }
