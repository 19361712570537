import React, { useEffect, useMemo, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTriaName } from "../../../../../hooks/useTriaName";
import {
  formatTriaName,
  RootState,
  sendOverlayMessage,
  updateAANetworks,
  updateNetworks,
  updateRefreshLoad,
  updateReload,
} from "../../../../rx.core";
import { getWalletStoreLocalStorage } from "../../../../rx.core/src/utils/getWalletStoreLocalStorage";
import { Refresh } from "../../components";
import { ArrowButton, CopyButton } from "../../components/Buttons";
import { UserAvatar } from "../../components/Containers/user/UserAvatar";
import Accounts from "../../components/Popups/Accounts";
import Chains from "../../components/Popups/Chains";
import { UserMenu } from "../../components/Popups/UserMenu";
import { useTriaUser } from "../../contexts";
import { useReload } from "../../contexts/reload/ReloadContext";

export const Nav: React.FC = () => {
  const [, setAccountsToggle] = useState(false);
  const { getAllNetworks } = useTriaUser();
  const [isChainToggleOpen, setIsChainToggleOpen] = useState(false);
  const [isAccountsOpen, setIsAccountsOpen] = useState(false);
  const [isUserPopupOpen, setIsUserPopupOpen] = useState(false);
  const { triaName } = useTriaName();
  const [loading, setLoading] = useState<boolean>();
  const { reload, toggleReload } = useReload();
  const refreshLoading = useSelector(
    (store: RootState) => store?.AppState?.AppCurrentState?.refreshLoading
  );
  // const reload = useSelector(
  //   (store: RootState) => store.AppState?.AppCurrentState?.reload
  // )
  const dispatch = useDispatch();

  const { userAddresses, userNonEvmAddresses } = useMemo(() => {
    let userAddresses = "";
    let otherAddresses: Array<{ chainName: string; address: string }> = [];

    const parsedData = getWalletStoreLocalStorage();
    if (parsedData) {
      userAddresses =
        parsedData?.addresses?.evm?.address || parsedData?.userAddress || "";

      parsedData?.addresses &&
        Object?.entries(parsedData?.addresses)?.forEach(([key, value]) => {
          if (key !== "triaName" && key !== "evm") {
            // Check if the value is an object and has an 'address' property
            if (
              typeof value === "object" &&
              value !== null &&
              "address" in value
            ) {
              otherAddresses?.push({
                chainName: key,
                address: value.address as string,
              });
            }
          }
        });
      parsedData?.addresses?.nonEvm.forEach((el) => {
        otherAddresses?.push({
          chainName: el.chainName,
          address: el.address,
        });
      });
    }
    return {
      userAddresses,
      userNonEvmAddresses: otherAddresses,
    };
  }, []);

  const selectRef = useRef<HTMLDivElement>(null);

  const networkItems = useSelector(
    (store: RootState) => store?.Constants?.NetworkItems
  );
  const fetchNetworks = async () => {
    if (!networkItems.length || reload) {
      try {
        const networks = await getAllNetworks("EOA");
        const AAnetworks = await getAllNetworks("AA");
        console.log("network eoa -->", networks);
        console.log("network aa -->", AAnetworks);
        dispatch(updateNetworks(networks));
        dispatch(updateAANetworks(AAnetworks));
      } catch (error: any) {
        console.error(error);
      }
    }
  };
  useEffect(() => {
    console.log("relaod--->", reload);
    if (reload) {
      console.log("hello");
      console.log(networkItems);
      fetchNetworks();
    }
  }, [reload]);

  const userStatus = useSelector(
    (store: RootState) => store.User.userStatusOnChain
  );

  // Side effect
  useEffect(() => {
    async function initalFetch() {
      try {
        fetchNetworks();
        // await fetchAssets()
      } catch (error: any) {
        console.error(error);
      }
    }
    initalFetch();
  }, []);

  const activeChain = useMemo(() => {
    const result = networkItems.find(
      (network: any) => network?.chainName === userStatus?.chainName
    );
    return result;
  }, [networkItems, userStatus?.chainName]);

  const handleAllAddressesClick = () => {
    sendOverlayMessage("showOverlay", true);
    setIsAccountsOpen(!isAccountsOpen);
  };

  const handlePopUp = () => {
    setIsChainToggleOpen(false);
  };

  useEffect(() => {
    const checkIfClickedOutside = (event: MouseEvent) => {
      if (
        (isChainToggleOpen || isAccountsOpen || isUserPopupOpen) &&
        selectRef.current &&
        !selectRef.current.contains(event.target as Node)
      ) {
        setIsChainToggleOpen(false);
        sendOverlayMessage("showOverlay", false);
        setIsAccountsOpen(false);
        setIsUserPopupOpen(false);
      }
    };

    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [isChainToggleOpen, isAccountsOpen, isUserPopupOpen]);

  const handleReload = () => {
    localStorage.removeItem("persist:root");
    toggleReload(true);
    dispatch(updateReload(true));
    dispatch(updateRefreshLoad(true));
    setLoading(true);
  };

  useEffect(() => {
    if (!refreshLoading) {
      toggleReload(false);
      setLoading(false);
    } else {
      toggleReload(false);
      setLoading(true);
    }
  }, [refreshLoading]);

  return (
    <>
      <div className="bg-primaryColor dark:bg-primaryDarkColor self-stretch h-[10vh] py-[2vh] rounded-[2vh]  flex-col justify-center items-center gap-[1vh] z-[200] flex relative ">
        {isChainToggleOpen && (
          <>
            <div className="w-[100%] h-[100%] fixed bg-[#101010] opacity-60 top-0 left-0 z-50" />
            <div
              className={`absolute top-[7vh] right-[1.2vh]  flex  bg-primaryColor dark:bg-primaryDarkColor rounded-[2vh] z-50 opacity-100 ${
                isChainToggleOpen ? "" : "hidden"
              }`}
              ref={selectRef}
              id="chain-toggle-ref"
            >
              <Chains network={networkItems} onClick={handlePopUp} />
            </div>
          </>
        )}
        {isAccountsOpen && (
          <>
            <div className="w-[100%] h-[100%] fixed bg-[#101010] opacity-60 top-0 left-0 z-50" />
            <div
              className={`w-[80%] h-auto shadow-[2vh] absolute top-[7vh] right-[5vh] flex rounded-lg z-50 ${
                isAccountsOpen ? "" : "hidden"
              }`}
              ref={selectRef}
              id="accounts-toggle-ref"
            >
              <Accounts
                addresses={userAddresses}
                activeChain={activeChain}
                nonEvmAddresses={userNonEvmAddresses}
              />
            </div>
          </>
        )}
        {isUserPopupOpen && (
          <>
            <div className="w-[100%] h-[100%] fixed bg-[#101010] opacity-60 top-0 left-0 z-50" />
            <div
              className={`w-[80%] shadow-[2vh] absolute top-[7vh] left-0  flex p-[1vh]  rounded-[1vh] z-50 ${
                isUserPopupOpen ? "" : "hidden"
              }`}
              ref={selectRef}
              id="user-toggle-ref"
            >
              <UserMenu />
            </div>
          </>
        )}
        <div className="self-stretch p-[1vh] justify-between items-center inline-flex">
          <div className="grow shrink basis-0 h-[5.2vh] justify-between items-center flex">
            <div
              className="group justify-center items-center flex cursor-pointer"
              onClick={() => {
                setIsUserPopupOpen(!isUserPopupOpen);
                sendOverlayMessage("showOverlay", true);
              }}
              ref={selectRef}
              id="user-toggle-ref"
            >
              <UserAvatar />

              <div className="px-[1vh] justify-start items-center flex">
                <div className="text-center text-black dark:text-[#FAFAFACC] group-hover:dark:text-[#FFFFFF] bg-opacity-90 text-opacity-80 text-[2.3vh] font-semibold leading-tight">
                  {triaName && triaName?.length > 20
                    ? formatTriaName(triaName)
                    : triaName}
                </div>
              </div>
              <div
                className={`w-[2.8vh] h-[2.8vh] relative flex items-center justify-start group-hover:translate-y-[0.5vh] transform
                 duration-300`}
              >
                <ArrowButton />
              </div>
            </div>
            <div className="justify-end items-center gap-[2vh] flex">
              <div
                className={` z-20 ${
                  loading ? "rotate-animation" : ""
                } cursor-pointer`}
                onClick={handleReload}
              >
                <div
                  className={`invisble h-0 dark:flex dark:h-full items-center ${
                    loading ? "" : "hover:rotate-[45deg]"
                  } transform duration-300`}
                >
                  <Refresh color="#FAFAFA" />
                </div>
                <div
                  className={`flex dark:hidden dark:w-0 dark:h-0 items-center ${
                    loading ? "" : "hover:rotate-[45deg]"
                  } transform duration-300`}
                >
                  <Refresh color="#101010" />
                </div>
              </div>
              <div className="w-[2vh]">
                <CopyButton onClick={handleAllAddressesClick} />
              </div>
              <div
                className="w-[5vh] h-[5vh] backdrop-blur-[3.50px] p-[0.5vh] flex justify-center items-center cursor-pointer "
                onClick={() => {
                  setIsChainToggleOpen(!isChainToggleOpen);
                  setAccountsToggle(false);
                  console.log("Primary calling");
                  sendOverlayMessage("showOverlay", true);
                }}
              >
                <div className="w-[5vh] h-[5vh]  " />
                <img
                  className="rounded-[0.8vh] overflow-hidden hover:translate-y-[-0.5vh] transform duration-300"
                  src={activeChain?.logo}
                  alt="logo"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
