import { useSelector } from "react-redux";
import {
  CustomChainDataType,
  GetAssetDetailsResponse,
  RootState,
} from "../package/rx.core";
import { useTriaUser } from "../package/ui.common/src/contexts/tria-user-provider/useTriaUser";
import { useUserPrefs } from "./useFetchUserPrefs";
import { useTriaName } from "./useTriaName";

import { useQuery, UseQueryOptions } from "@tanstack/react-query";

export const useAssetDetails = (
  params?: Omit<
    UseQueryOptions<GetAssetDetailsResponse["data"]>,
    "queryKey" | "queryFn"
  >
) => {
  const { getAssetDetails } = useTriaUser();

  const { triaName } = useTriaName();
  const { chainName, tokenAddress, isCustomToken } = useSelector(
    (store: RootState) => store.Asset.fetchParam
  );

  const { data } = useUserPrefs();
  let chain: CustomChainDataType | undefined;
  if (isCustomToken) {
    chain = data?.customChains.find((chain) => {
      return chain?.chainName === chainName;
    });
  }

  const enabledParams =
    !!triaName && (!isCustomToken || (isCustomToken && !!chain));

  const query = useQuery<GetAssetDetailsResponse["data"]>({
    queryKey: ["getAssetDetails", triaName, chainName, tokenAddress],
    queryFn: async () => {
      const accessToken = localStorage.getItem("tria.accessToken");
      const resp = await getAssetDetails({
        chainName,
        tokenAddress,
        chain,
        accessToken: accessToken || undefined,
      });
      if (resp instanceof Error) {
        return Promise.reject(new Error(resp.message));
      }
      const { success, data, message } = resp;
      if (!success) {
        const messageError =
          data && "message" in data ? (data.message as string) : message;
        return Promise.reject(
          new Error(messageError, {
            cause: "handled",
          })
        );
      }
      if (data) {
        return data;
      }
      return Promise.reject(new Error(message));
    },
    retry: (count, error) => {
      return count < 3 && error.cause !== "handled";
    },
    staleTime: 5 * 10 * 1000,
    enabled: (params?.enabled ?? true) && enabledParams,
    ...params,
  });

  return query;
};
