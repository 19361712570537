var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import BackendService from "./index";
import { errorHandler } from "../utils/helper";
import { formatChainNames } from "../utils/format";
import { chainNameToLogo, chainNameToLogoBlack, isAaSupportedChain, chainNameToLogoAverageColor, networks, } from "../utils/constants";
class userService extends BackendService {
    constructor(apiUrl, environment = "mainnet") {
        super(apiUrl);
        this.environment = environment;
    }
    searchAssets({ term }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/searchAssets", {
                    params: {
                        query: term,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    searchUser({ term, page, limit, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/searchUser", {
                    params: {
                        query: term,
                        page,
                        limit,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getAllHistory(triaName, filter = "all", filterChainNames) {
        return __awaiter(this, void 0, void 0, function* () {
            if (filterChainNames) {
                var chainNames = formatChainNames(filterChainNames);
            }
            try {
                const { data } = yield this.triaAxios.get("/user/getAllHistory", {
                    params: {
                        triaName,
                        filter,
                        filterChainNames: chainNames,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getOnChainConversation(triaName, withTriaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/user/getOnChainConversation", {
                    params: {
                        triaName,
                        withTriaName,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getTotalBalance(triaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/user/getTotalBalance", {
                    params: {
                        triaName,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getAssets(triaName, filterChainNames, filter, sort) {
        return __awaiter(this, void 0, void 0, function* () {
            if (filterChainNames) {
                var chainNames = formatChainNames(filterChainNames);
            }
            try {
                const { data } = yield this.triaAxios.get("/user/getAssets", {
                    params: {
                        triaName,
                        filterChainNames: chainNames,
                        filter,
                        sort,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getAssetsForATriaName(triaName, filterChainNames, sort) {
        return __awaiter(this, void 0, void 0, function* () {
            if (filterChainNames) {
                var chainNames = formatChainNames(filterChainNames);
            }
            try {
                const { data } = yield this.triaAxios.get("/user/getAssetsForATriaName", {
                    params: {
                        triaName,
                        filterChainNames: chainNames,
                        sort,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getAssetBalanceForATriaName(triaName, chainName, tokenAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/user/getAssetBalanceForATriaName", {
                    triaName,
                    chainName,
                    tokenAddress,
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getAssetDetails({ triaName, chain, tokenAddress, type, accessToken, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/user/getAssetDetails", Object.assign(Object.assign({ triaName }, chain), { tokenAddress,
                    type }), accessToken
                    ? {
                        headers: {
                            Authorization: `Bearer ${accessToken}`,
                        },
                    }
                    : undefined);
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getAssetHistory(triaName, chainName, tokenAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/user/getAssetHistory", {
                    params: {
                        triaName,
                        chainName,
                        tokenAddress,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getNFTs(triaName, sort = "Descending buy date", filter, filterByCollectionId, filterChainNames) {
        return __awaiter(this, void 0, void 0, function* () {
            if (filterChainNames) {
                var chainNames = formatChainNames(filterChainNames);
            }
            try {
                const { data } = yield this.triaAxios.get("/user/getNFTs", {
                    params: {
                        triaName,
                        filter,
                        sort,
                        filterByCollectionId,
                        filterChainNames: chainNames,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getNFTCollections(triaName, filterChainNames) {
        return __awaiter(this, void 0, void 0, function* () {
            if (filterChainNames) {
                var chainNames = formatChainNames(filterChainNames);
            }
            try {
                const { data } = yield this.triaAxios.get("/user/getNFTCollections", {
                    params: {
                        triaName,
                        filterChainNames: chainNames,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getNFTDetails(triaName, chainName, tokenAddress, tokenId, type, includeAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/user/getNFTDetails", {
                    params: {
                        triaName,
                        chainName,
                        tokenAddress,
                        tokenId,
                        type,
                        includeAmount,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getUserByAddress(address, chainName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/wallet/resolve-address", {
                    params: {
                        address,
                        chainName,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getAddressByChainName(triaName, chainName, type) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/wallet/resolveTriaName", {
                    chainName,
                    lookUpTriaName: triaName,
                    type,
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getRampnalysisUrl(address, coinSymbol) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/get-rampnalysis-url", {
                    params: {
                        address,
                        coinSymbol,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getRampnalysisSupportedToken(query) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/getRampnalysisSupportedToken", {
                    params: {
                        query,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    convertToken(chainName, fromToken, fromAmount, toToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/convertToken", {
                    chainName,
                    fromToken,
                    fromAmount,
                    toToken,
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getAllNetworks(type = "EOA") {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                // const { data } = await this.triaAxios.get('/getAllNetworks');
                let allNetworks = networks;
                if (type == "AA") {
                    allNetworks = networks.filter((n) => isAaSupportedChain(n.chainName));
                }
                // filter networks
                const data = {
                    success: true,
                    networks: allNetworks,
                    chainNameToLogo,
                    chainNameToLogoBlack,
                    chainNameToLogoAverageColor,
                };
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getAssetChart(timePeriod, chainName, tokenAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/getAssetChart", {
                    params: {
                        timePeriod,
                        chainName,
                        tokenAddress,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    updateAvatar(accessToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/user/update-avatar", {}, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getAvatar(accessToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/user/get-avatar", {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    postAccountCreationData(triaName, accessToken, encyRandomNumber, recoveryShard, salt, IdToken, input, fromClientId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/user/set-avatar", {
                    triaName,
                    shard: recoveryShard,
                    encyRandomNumber: encyRandomNumber,
                    salt: salt,
                    IdToken: IdToken,
                    input,
                    fromClientId,
                }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getPopularToken() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/get-popular-token");
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    searchBuyToken(tokenName, currentChainName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/search-buy-assets", {
                    params: { tokenName, currentChainName },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    addUserActivity(triaName, sentToTriaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/user/activity", {
                    triaName,
                    sentToTriaName,
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getRecentUsersForUser(triaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/user/recentUsers", {
                    params: {
                        triaName,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    deleteARecentUser(user, recentUser) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/user/activity/delete", {
                    user,
                    recentUser,
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    isRecoveryShardSetup(accessToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/user/isRecoveryShardSetup", {}, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`, // Set the Authorization header with the Bearer token
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    setupRecoveryShard({ pin, shard, encyRandomNumber, derivedKeySalt, accessToken, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/user/setupRecoveryShard", {
                    pin,
                    shard,
                    encyRandomNumber,
                    derivedKeySalt,
                }, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    fundWallet({ walletAddress, origin, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/wallet/fundWallet", {
                    walletAddress,
                    origin,
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getNativeTokenPrice(chainName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/getNativeTokenPrice", {
                    params: {
                        chainName,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getTwitterFeed(accessToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/twitter/feed", {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getUserPrefs(accessToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.get("/userPref", {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    addCustomChain({ accessToken, customChain, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/customChain/add", Object.assign({}, customChain), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    updateCustomChain({ accessToken, customChain, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.put(`/customChain/update?chainId=${customChain === null || customChain === void 0 ? void 0 : customChain.chainId}`, Object.assign({}, customChain), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    deleteCustomChain({ accessToken, customChain, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.delete(`/customChain/delete?chainId=${customChain.chainId}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    addCustomToken({ accessToken, customToken, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.post("/customToken/add", Object.assign({}, customToken), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getSuggestedDapps() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data: { data }, } = yield this.triaAxios.get("/get-suggested-dapp");
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    getNotificationPreference() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data: { notificationPreferences }, } = yield this.triaAxios.get("/userPref/get-notification-preference");
                return notificationPreferences;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    updateNotificationPreference(updatedNotificationPreference) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data: { updatedData }, } = yield this.triaAxios.post("/userPref/update-notification-preference", {
                    updatedNotificationPreference,
                });
                return updatedData;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    updateCustomToken({ accessToken, customToken, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.put(`/customToken/update?chainId=${customToken === null || customToken === void 0 ? void 0 : customToken.chainId}&tokenAddress=${customToken === null || customToken === void 0 ? void 0 : customToken.tokenAddress}`, Object.assign({}, customToken), {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
    deleteCustomToken({ accessToken, customToken, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const { data } = yield this.triaAxios.delete(`/customToken/delete?chainId=${customToken === null || customToken === void 0 ? void 0 : customToken.chainId}&tokenAddress=${customToken === null || customToken === void 0 ? void 0 : customToken.tokenAddress}`, {
                    headers: {
                        Authorization: `Bearer ${accessToken}`,
                    },
                });
                return data;
            }
            catch (err) {
                return errorHandler(err);
            }
        });
    }
}
export default userService;
