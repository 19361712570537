var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
import UserService from "../services/user";
import GreenfieldService from "../services/greenfield";
import { prodApiUrl } from "../utils/constants";
export class UserController {
    /**
     *
     * @param baseUrl api url
     * @param triaName default triaName for all functions
     * @param environment mainnet or testnet
     */
    constructor(baseUrl = prodApiUrl, triaName, environment = "mainnet") {
        this.baseUrl = baseUrl;
        this.user = new UserService(baseUrl, environment);
        this.greenfield = new GreenfieldService(baseUrl);
        this.triaName = triaName;
    }
    searchAssets(term) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.searchAssets({ term });
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    searchUser(term, page, limit) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.searchUser({ term, page, limit });
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getAllHistory(filter = "all", filterChainNames, triaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getAllHistory((this.triaName || triaName), filter, filterChainNames);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getOnChainConversation(withTriaName, triaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getOnChainConversation((this.triaName || triaName), withTriaName);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getAssets(filterChainNames, filter, sort, triaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getAssets((this.triaName || triaName), filterChainNames, filter, sort);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getAssetsForATriaName(triaName, filterChainNames, sort) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getAssetsForATriaName(triaName, filterChainNames, sort);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getAssetBalanceForATriaName(triaName, chainName, tokenAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getAssetBalanceForATriaName(triaName, chainName, tokenAddress);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getAssetDetails({ chain, tokenAddress, triaName, type, accessToken, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getAssetDetails({
                    triaName: (this.triaName || triaName),
                    chain,
                    tokenAddress,
                    type,
                    accessToken,
                });
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getAssetHistory(chainName, tokenAddress, triaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getAssetHistory((this.triaName || triaName), chainName, tokenAddress);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getNFTs(sort = "Descending buy date", filter, filterByCollectionId, filterChainNames, triaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getNFTs((this.triaName || triaName), sort, filter, filterByCollectionId, filterChainNames);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getNFTCollections(filterChainNames, triaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getNFTCollections((this.triaName || triaName), filterChainNames);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getNFTDetails(chainName, tokenAddress, tokenId, triaName, type, includeAmount) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getNFTDetails((this.triaName || triaName), chainName, tokenAddress, tokenId, type, includeAmount);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getUserByAddress(address, chainName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getUserByAddress(address, chainName);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getAddressByChainName(triaName, chainName, type) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getAddressByChainName(triaName, chainName, type);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    convertToken(chainName, fromToken, fromAmount, toToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.convertToken(chainName, fromToken, fromAmount, toToken);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getRampnalysisUrl(address, coinSymbol) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getRampnalysisUrl(address, coinSymbol);
                return res;
            }
            catch (err) {
                return { success: false, url: null };
            }
        });
    }
    getRampnalysisSupportedToken(query) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getRampnalysisSupportedToken(query);
                return res;
            }
            catch (err) {
                return { success: false, data: null };
            }
        });
    }
    getAllNetworks(type = "EOA") {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getAllNetworks(type);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getAssetChart(timePeriod, chainName, tokenAddress) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getAssetChart(timePeriod, chainName, tokenAddress);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getTotalBalance(triaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getTotalBalance((this.triaName || triaName));
                return res;
            }
            catch (err) {
                return { success: false, message: "Unable to fetch total balance" };
            }
        });
    }
    getAvatar(accessToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getAvatar(accessToken);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    postAccountCreationData(triaName, accessToken, encyRandomNumber, recoveryShard, salt, IdToken, input, fromClientId) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.postAccountCreationData(triaName, accessToken, encyRandomNumber, recoveryShard, salt, IdToken, input, fromClientId);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    updateAvatar(accessToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.updateAvatar(accessToken);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getPopularToken() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getPopularToken();
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    searchBuyToken(tokenName, currentChainName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.searchBuyToken(tokenName, currentChainName);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    addUserActivity(triaName, sentToTriaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.addUserActivity(triaName, sentToTriaName);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getRecentUsersForUser(triaName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getRecentUsersForUser(triaName);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    deleteARecentUser(user, recentUser) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.deleteARecentUser(user, recentUser);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    isRecoveryShardSetup(accessToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.isRecoveryShardSetup(accessToken);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    setupRecoveryShard({ pin, shard, encyRandomNumber, derivedKeySalt, accessToken, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.setupRecoveryShard({
                    pin,
                    shard,
                    encyRandomNumber,
                    derivedKeySalt,
                    accessToken,
                });
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    fundWallet({ walletAddress, origin, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.fundWallet({ walletAddress, origin });
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    uploadGreenfield({ triaName }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.greenfield.upload(triaName);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    fetchGreenfield({ triaName }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.greenfield.fetch(triaName);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getNativeTokenPrice(chainName) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getNativeTokenPrice(chainName);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getTwitterFeed(accessToken) {
        return __awaiter(this, void 0, void 0, function* () {
            const res = (yield this.user.getTwitterFeed(accessToken));
            if (res.success) {
                return res;
            }
            else {
                return res;
            }
        });
    }
    getUserPrefs(accessToken) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getUserPrefs(accessToken);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    addCustomChain({ accessToken, customChain, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.addCustomChain({ accessToken, customChain });
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    updateCustomChain({ accessToken, customChain, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.updateCustomChain({
                    accessToken,
                    customChain,
                });
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    deleteCustomChain({ accessToken, customChain, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.deleteCustomChain({
                    accessToken,
                    customChain,
                });
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    addCustomToken({ accessToken, customToken, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.addCustomToken({
                    accessToken,
                    customToken,
                });
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    updateCustomToken({ accessToken, customToken, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.updateCustomToken({
                    accessToken,
                    customToken,
                });
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getSuggestedDapps() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getSuggestedDapps();
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    getNotificationPreference() {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.getNotificationPreference();
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    updateNotificationPreference(notificationPreferences) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.updateNotificationPreference(notificationPreferences);
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
    deleteCustomToken({ accessToken, customToken, }) {
        return __awaiter(this, void 0, void 0, function* () {
            try {
                const res = yield this.user.deleteCustomToken({
                    accessToken,
                    customToken,
                });
                return res;
            }
            catch (err) {
                return err;
            }
        });
    }
}
