import TransactionDetailCrypto from "./package/ui.common/src/layouts/Activity/TransactionDetailCrypto";
import TransactionListPage from "./pages/activity";
import Home from "./pages/home";
import Nft from "./pages/nft";

import { useCallback, useEffect, useState } from "react";
import { Route, Routes, useParams } from "react-router-dom";
import { useHandleParams } from "./hooks";
import "./index.css";
import { AuthIframe } from "./package/ui.common/src/components";
import OneNft from "./package/ui.common/src/layouts/Nfts/OneNft";
import HomeBackgroundVector from "./package/ui.common/src/layouts/UI/HomeBackgroundVector";
import { AssetDetailHistory } from "./pages/assethistory";
import { BuyCrypto } from "./pages/buycrypto";
import { UserConversations } from "./pages/conversations";
import { SendCrypto } from "./pages/sendcrypto";
import { SendToken } from "./pages/sendtoken";

import { useDispatch } from "react-redux";
import { useDetectInactivity } from "./hooks/useDetectInactivity";
import { updateSocialLoginStatus } from "./package/rx.core";
import { LogoutModal } from "./package/ui.common/src/components/Popups/LogoutModal";
import { Toast } from "./package/ui.common/src/components/Toast";
import TransactionListPagePending from "./pages/activitypending";
import AddCustomChain from "./pages/addcustomchain";
import AddCustomToken from "./pages/addCustomToken";
import ApproveCustomChain from "./pages/approvecustomchain";
import CustomChain from "./pages/customchain";
import CustomToken from "./pages/customtoken";
import EditChain from "./pages/editchain";
import EditToken from "./pages/edittoken";
import { SendNft } from "./pages/sendnft";
import { Swap } from "./pages/swap";
import { getWalletStoreLocalStorage } from "./package/rx.core/src/utils/getWalletStoreLocalStorage";
import Lite from "./pages/lite";
import { useQueryClient } from "@tanstack/react-query";
import { useTriaUser } from "./package/ui.common";

const App = () => {
  const [showLogoutModal, setShowLogoutModal] = useState<boolean>(false);
  const param = useParams();
  const { isLoading, liteMode } = useHandleParams(param.params || "");
  const AUTH_URL = process.env.REACT_APP_AUTH_URL || "";
  const queryClient = useQueryClient();
  const { getUserPrefs } = useTriaUser();

  const dispatch = useDispatch();
  useDetectInactivity();

  useEffect(() => {
    console.log("call for eventsource");
    const eventSource = new EventSource(
      `${process.env.REACT_APP_GAINS_URL}/events`
    );

    eventSource.onmessage = (event) => {
      const data = JSON.parse(event.data);
      if (data.status === "loggedIn") {
        console.log("call eventsource response", event.data);

        const triaName = getWalletStoreLocalStorage()?.triaName;
        if (data.userId === triaName) {
          dispatch(updateSocialLoginStatus(true));
          localStorage.setItem("socialLoggedIn", "true");
        }
      }
    };

    eventSource.onerror = (event) => {
      console.log(event);
    };

    return () => {
      eventSource.close();
    };
  }, [dispatch]);

  useEffect(() => {
    const preloader = document.getElementById("preloader");
    if (preloader) {
      // Hide the preloader once the app is loaded
      const theme = localStorage.getItem("theme");
      if (theme && theme === "dark") {
        document.documentElement.classList.add("dark");
      } else {
        document.documentElement.classList.remove("dark");
      }
      preloader.style.display = "none";
    }
  }, []);
  const fetchAndSendUserPrefs = useCallback(
    async (event: MessageEvent<any>): Promise<void> => {
      const data = await getUserPrefs();
      if (data instanceof Error) {
        return Promise.reject(new Error(data.message));
      }

      window.parent.postMessage(
        { type: "user-prefs-res", customChains: data?.customChains },
        event.origin
      );
    },
    [getUserPrefs]
  );
  useEffect(() => {
    const handleMessage = (event: MessageEvent<any>) => {
      console.log("parent domain", process.env.REACT_APP_AUTH_URL, event);
      if (event?.data?.type === "fetch-user-prefs") {
        fetchAndSendUserPrefs(event);
      } else if (event?.data?.type === "accesstoken-call") {
        if (event.origin === process.env.REACT_APP_AUTH_URL) {
          console.log("Received message from parent:", event);

          if (event.source) {
            console.log("token event", event);
            localStorage.setItem("tria.accessToken", event?.data?.token);
            (event.source as Window).postMessage("Received", event.origin);
          }
        }
      } else if (event?.data?.type === "disconnect-called") {
        console.log("Disconnect called message received");
        // Call your desired function here
        localStorage.clear();
        window.parent.postMessage({ type: "disconnect-callback" }, "*");
      } else if (event?.data?.type === "reset-user-prefs") {
        queryClient.resetQueries();
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [fetchAndSendUserPrefs, queryClient]);

  useEffect(() => {
    const initialize = async () => {
      // await listenForAccessToken({ authUrl: AUTH_URL })
      // await listenIdToken({ authUrl: AUTH_URL })
    };

    let cleanupFunction: any;
    initialize().then((cleanup) => {
      cleanupFunction = cleanup;
    });

    return () => {
      if (cleanupFunction) cleanupFunction();
    };
  }, []);

  return (
    <>
      <AuthIframe src={AUTH_URL} />
      {!isLoading && (
        <div className={`overflow-hidden`}>
          <div
            className={`${
              liteMode ? "" : "rounded-2xl"
            } z-50 bg-primaryColor dark:bg-primaryDarkColor overflow-hidden select-none`}
          >
            <div className="absolute top-0 moving-div">
              {" "}
              <HomeBackgroundVector />
            </div>
            {showLogoutModal && (
              <div style={{ zIndex: 1000 }}>
                {process.env.REACT_APP_ENVIRONMENT === "local" ? null : (
                  <LogoutModal setOpenState={setShowLogoutModal} />
                )}
              </div>
            )}
            <div style={{ zIndex: 1000 }}>
              <Toast />
            </div>

            <Routes>
              <Route path="/:params" element={<App />} />
              <Route path="/home" element={<Home />} />
              <Route path="/lite" element={<Lite />} />
              <Route path="/nfts" element={<Nft />} />
              <Route path="/home/buycrypto" element={<BuyCrypto />} />
              <Route path="/home/sendCrypto" element={<SendCrypto />} />
              <Route
                path="/home/sendCrypto/conversations"
                element={<UserConversations />}
              />
              <Route
                path="/home/sendCrypto/cryptoSendPage"
                element={<SendToken />}
              />
              <Route path="/activity" element={<TransactionListPage />} />
              <Route
                path="/activities/:params"
                element={<TransactionListPagePending />}
              />
              <Route
                path="/activity/TransactionDetailCrypto"
                element={<TransactionDetailCrypto />}
              />
              <Route path="/nfts/oneNft" element={<OneNft />} />
              <Route path="/home/sendCrypto/nftsend" element={<SendNft />} />
              <Route path="/assethistory" element={<AssetDetailHistory />} />
              <Route path="/swap" element={<Swap />} />
              <Route path="/addcustomchain" element={<AddCustomChain />} />
              <Route path="/addcustomtoken" element={<AddCustomToken />} />
              <Route
                path="/approvecustomchain"
                element={<ApproveCustomChain />}
              />
              <Route path="/customchain" element={<CustomChain />} />
              <Route path="/customtoken" element={<CustomToken />} />
              <Route path="/edittoken" element={<EditToken />} />
              <Route path="/editchain" element={<EditChain />} />
            </Routes>
          </div>
        </div>
      )}
    </>
  );
};

export default App;
