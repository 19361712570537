import { getChains, getTokens } from "@tria-sdk/connect";
import React, { useEffect, useRef, useState } from "react";
export const walletType = { embedded: true };
const baseUrl = process.env.REACT_APP_SDK_BASE_URL || "";

interface ToSelectDropDownProps {
  toChainData: any;
  toChainIndexSelected: any;
  toTokenSelectLifi: any;
  toChainIdSelected: any;
  setToChainData: any;
  setToChainIndexSelected: any;
  setToTokenSelectLifi: any;
  setToChainIdSelected: any;
  setOverlayVisible: any;
  overlayVisible: any;
  setToChainDataSelected: any;
  toChainDataSelected: any;
  fromChainIdSelected: any;
  chainDataSelected: any;
  liteMode?: boolean;
}

const ToSelectDropDown: React.FC<ToSelectDropDownProps> = ({
  chainDataSelected,
  toChainDataSelected,
  setToChainDataSelected,
  toChainData,
  toChainIndexSelected,
  toTokenSelectLifi,
  toChainIdSelected,
  setToChainData,
  setToChainIndexSelected,
  setToTokenSelectLifi,
  setToChainIdSelected,
  setOverlayVisible,
  overlayVisible,
  fromChainIdSelected,
  liteMode,
}) => {
  const [isToDropdownOpen, setIsToDropdownOpen] = useState(false);

  const [toVisibleData, setToVisibleData] = useState([]);
  // const [overlayVisible, setOverlayVisible] = useState<boolean>(false);
  const [toDropDownClick, setToDropDownClick] = useState<boolean>(false);
  const [toTokenIndexSelected, setToTokenIndexSelected] = useState(0);
  const [filterToTokensData, setFilterToTokensData] = useState<any[]>([]);
  const [filterToChainData, setFilterToChainData] = useState<any[]>([]);
  const [toAlltokensLifi, setToAllTokensLifi] = useState<any[]>([]);
  const [toSelectedData, setToSelectedData] = useState(false);
  const [toSearchQueryLifiTokens, setToSearchQueryLifiTokens] =
    useState<string>("");
  const [toSearchQueryLifiChains, setToSearchQueryLifiChains] =
    useState<string>("");
  const [pageNumber, setPageNumber] = useState(1);
  const ulRef = useRef(null);
  // const [toChainDataSelected, setToChainDataSelected] = useState();

  const toHandleSearchChangeLifiTokens = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const q =
      e.target.value.trim() === ""
        ? toAlltokensLifi?.[toChainIdSelected]
        : toAlltokensLifi?.[toChainIdSelected]?.filter((token: any) =>
            token?.symbol?.toLowerCase().includes(e.target.value.toLowerCase())
          );
    setFilterToTokensData(q);
    setToSearchQueryLifiTokens(e.target.value);
  };

  const toHandleSearchChangeLifiChains = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    const q =
      e.target.value.trim() === ""
        ? toChainData // If search box is empty, show all tokens
        : toChainData?.filter((token: any) =>
            token?.name?.toLowerCase().includes(e.target.value.toLowerCase())
          );
    setFilterToChainData(q);
    setToSearchQueryLifiChains(e.target.value);
  };

  const handleToDropDownClick = () => {
    setToDropDownClick(!toDropDownClick);
    setOverlayVisible(!overlayVisible);
  };

  const handleToToggleDropdown = () => {
    setIsToDropdownOpen(!isToDropdownOpen);
  };

  // Fetch more data when the user scrolls to the bottom
  const handleScroll = () => {
    const ul: any = ulRef.current;

    if (ul.scrollTop + ul.clientHeight >= ul.scrollHeight - 20) {
      setPageNumber((prevPageNumber) => prevPageNumber + 1);
    }
  };

  const [hasSetToChainDataSelected, setHasSetToChainDataSelected] =
    useState(false);

  // Update visible data when data changes
  useEffect(() => {
    setToVisibleData(
      toAlltokensLifi?.[chainDataSelected?.id]?.slice(0, pageNumber * 5)
    );
  }, [toAlltokensLifi, fromChainIdSelected, chainDataSelected, pageNumber]);
  {
    console.error("toVisibleData", toVisibleData);
  }

  // Fetch more data when the page number changes
  useEffect(() => {
    fetchData();
  }, [pageNumber]);

  const fetchData = async () => {
    try {
      const [tokens, chains] = await Promise.all([getTokens(), getChains()]);
      setToAllTokensLifi(tokens?.tokens);
      setToChainData(chains?.chains);
      // setToChainDataSelected(chains?.chains?.[0])
      if (!hasSetToChainDataSelected && chains?.chains?.length > 0) {
        setToChainDataSelected(chains?.chains?.[0]);
        setHasSetToChainDataSelected(true); // Mark that setToChainDataSelected has been called
      }
    } catch (err) {
      console.log(err);
    }
  };

  // useEffect(() => {
  //   setToChainIdSelected(toChainData[fromChainIdSelected].id);
  //   // setToChainIndexSelected(index)
  //   setToChainDataSelected(toChainData[fromChainIdSelected]);
  // }, [fromChainIdSelected]);

  return (
    <>
      {toSelectedData && (
        <div
          className=" self-stretch px-[2.88vh] py-[1.44vh] h-[10vh] dark:bg-zinc-900 bg-fontColorNftCardDark rounded-[8.8vh] flex-col justify-center items-center gap-[.96vh] inline-flex cursor-pointer"
          onClick={() => {
            handleToDropDownClick();
            setToDropDownClick(true);
            setToSelectedData(false);
          }}
        >
          <div className="self-stretch justify-start items-center gap-[1.92vh] inline-flex">
            <div className="grow shrink basis-0 h-[4.8vh] justify-start items-center gap-[1.44vh] flex">
              <div className="w-[4.8vh] h-[4.8vh] relative  shadow ">
                <img
                  src={toTokenSelectLifi?.logoURI}
                  className=" rounded-[28vh]"
                />
                <div className="w-[3.6vh] h-[3.6vh] left-[.6vh] top-[5.31vh] absolute" />
                <div className="left-[2.9vh] top-[3vh] absolute justify-start items-center gap-[.7vh] inline-flex">
                  <div className="w-[2.4vh] h-[2.4vh] relative  rounded  backdrop-blur-[2.50px]">
                    <img
                      src={chainDataSelected?.logoURI}
                      className="rounded border"
                    />
                    <div className="w-[1.8vh] h-[1.8vh] left-[.3vh] top-[.31vh] absolute" />
                  </div>
                </div>
              </div>
              <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex">
                <div className="self-stretch justify-start items-center gap-[.48vh] inline-flex">
                  <div className="dark:text-neutral-50 font-montserrat text-zinc-500  md:text-base text-sm font-semibold  -tight">
                    {toTokenSelectLifi?.symbol || ""}
                  </div>
                </div>
                <div className="text-zinc-500 font-montserrat md:text-sm text-xs font-semibold uppercase">
                  {chainDataSelected?.name || ""}
                </div>
              </div>
            </div>
            <div className="w-[2.6vh] h-[2.6vh] relative">
              <div className="w-[2.6vh] h-[2.6vh] left-0 top-0 absolute">
                <img
                  src="/icons/arrow-right2.svg"
                  className="dark:visible invisible w-0 dark:w-[2.6vh]"
                />
                <img
                  src="/icons/arrow-left.svg"
                  className="dark:invisible visible dark:w-0 rotate-180"
                />
              </div>
            </div>
          </div>
        </div>
      )}

      {!toSelectedData && (
        <>
          <div
            className="self-stretch  px-[2.88vh] py-[1.44vh] dark:bg-zinc-900 h-[10vh] bg-fontColorNftCardDark rounded-[8.8vh] flex-col justify-center items-center gap-[.96vh] flex cursor-pointer"
            onClick={() => {
              // fetchData();
              handleToDropDownClick();
            }}
          >
            <div className="self-stretch justify-start items-center gap-[1.92vh] inline-flex">
              <div className="grow shrink basis-0 h-[4.8vh] justify-start items-center gap-[1.44vh] flex">
                <div className="w-[4.8vh] h-[4.8vh] relative">
                  <div className="w-[4.8vh] h-[4.8vh] left-0 top-0 absolute dark:bg-zinc-800 bg-bgOfCircle rounded-[4.8vh]" />
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex">
                  <div className="self-stretch justify-start items-center gap-[.48vh] inline-flex">
                    <div className="text-zinc-500 font-montserrat md:text-base text-sm font-semibold  -tight">
                      Select crypto
                    </div>
                  </div>
                </div>
              </div>
              <div className="w-[2.6vh] h-[2.6vh] relative">
                <div className="w-[2.6vh] h-[2.6vh] left-0 top-0 absolute">
                  <img
                    src="/icons/arrow-right2.svg"
                    className="dark:visible invisible w-0 dark:w-[2.6vh]"
                  />
                  <img
                    src="/icons/arrow-left.svg"
                    className="dark:invisible visible dark:w-0 rotate-180"
                  />
                </div>
              </div>
            </div>
          </div>

          {overlayVisible && (
            <div
              style={{
                position: "absolute",
                // height: '50%',
                bottom: "24%",
                zIndex: 1001,
              }}
            >
              {toDropDownClick && !isToDropdownOpen && (
                <div
                  className={`${
                    liteMode ? "w-[100vw]" : "w-[49.9vh]"
                  }  h-[56.9vh] relative dark:bg-stone-950 bg-white rounded-[2.4vh]  border-zinc-500 border-opacity-60`}
                >
                  <div className=" left-0 right-0 top-0   absolute shadow flex-col justify-end items-center gap-[1.96vh] inline-flex">
                    <div className="self-stretch h-[14vh] flex-col justify-end items-center flex">
                      <div className="self-stretch  pt-[1.44vh] pb-[.48vh] rounded-tl-[2.4vh] rounded-tr-[2.4vh]  flex-col justify-center  items-center gap-[.96vh] flex">
                        <div className="self-stretch px-[2.4vh] py-[1.44vh] rounded-lg justify-center items-center gap-[.96vh] inline-flex">
                          <div className="grow shrink basis-0 h-[11vh justify-between items-center flex">
                            <div className="grow shrink basis-0 h-[11vh justify-start items-center mt-[10% gap-[1.44vh] flex">
                              <div className="flex-col justify-start items-start gap-[.48vh] inline-flex">
                                <div className="self-stretch justify-start items-center gap-[.96vh] inline-flex">
                                  <div className="text-center font-montserrat dark:text-neutral-50 md:text-base text-sm font-semibold  -tight">
                                    Select Crypto
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-[3.36vh] h-[3.36vh] relative mt-[10%">
                            <div
                              className="w-[3.36vh] h-[3.36vh] left-[0.01px] top-0 absolute cursor-pointer"
                              onClick={handleToDropDownClick}
                            >
                              <img
                                src="/icons/close-circle-dark.svg"
                                className="dark:visible invisible w-0 dark:w-[3.36vh]"
                              />
                              <img
                                src="/icons/close-circle2.svg"
                                className="dark:invisible visible dark:w-0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch h-[5.76vh] px-[1.44vh] justify-start items-center gap-[1.92vh] inline-flex">
                        <div className="grow shrink basis-0 h-[4vh] px-[1.44vh] py-[.96vh] dark:bg-zinc-900 bg-fontColorNftCardDark rounded-[3.6vh] justify-start items-center gap-[1.44vh] flex">
                          <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex">
                            <div className="self-stretch justify-between items-center inline-flex">
                              <input
                                type="text"
                                className="text-zinc-500 font-montserrat text-sm font-medium  -[16.9.6vh] w-full bg-transparent border-none focus:outline-none"
                                placeholder="Search crypto"
                                value={toSearchQueryLifiTokens}
                                onChange={toHandleSearchChangeLifiTokens}
                              />{" "}
                              <div className="w-[2.2vh] h-[2.2vh] relative">
                                <div className="w-[2.2vh] h-[2.2vh] left-0 top-0 absolute">
                                  <img src="/icons/search-normal.svg"></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch  flex-col justify-start items-center gap-[1.92vh] flex">
                      <div className="self-stretch  flex-col justify-center items-start gap-[2.4vh] flex">
                        <div className="px-[1.92vh]  justify-center items-start gap-[.96vh] inline-flex">
                          <div className=" dark:text-neutral-700 font-montserrat text-networkColor md:text-sm text-xs font-semibold  lowercase  tracking-wide">
                            networks
                          </div>
                        </div>
                        <div className="self-stretch px-[2.88vh] justify-between items-start inline-flex ">
                          {toChainData.length > 0 &&
                            toChainData
                              .slice(0, 4)
                              .map((item: any, index: any) => {
                                console.log(
                                  "fromChainIdSelected",
                                  fromChainIdSelected,
                                  item
                                );
                                return (
                                  <>
                                    <div
                                      key={index}
                                      className={`p-[1.4vh] rounded-md  border-2 ${
                                        chainDataSelected?.id === item?.id
                                          ? " border-violet-300 cursor-pointer bg-fontColorNftCardDark dark:bg-black"
                                          : "dark:border-zinc-900 blur-sm cursor-not-allowed"
                                      }
                                      
                                      justify-center items-center gap-[1.4vh]  flex 
                                  `}
                                    >
                                      <div className="justify-start items-center gap-[.12vh] flex">
                                        <div className="w-[3.84vh] h-[3.84vh] relative rounded-md  border-white backdrop-blur-sm">
                                          <div className="w-[2.88vh] h-[2.88vh] left-[4.01vh] top-[.5vh] absolute" />
                                          <img
                                            src={item?.logoURI}
                                            className="rounded border"
                                            alt={`Chain Logo ${item?.id}`}
                                          />
                                        </div>
                                      </div>
                                    </div>
                                  </>
                                );
                              })}
                          <div
                            className="p-[1.4vh] rounded-[1.3vh] bg-fontColorNftCardDark border-2 dark:bg-black  dark:border-zinc-900 justify-center items-center gap-[1.4vh] flex blur-sm cursor-not-allowed "
                            //  onClick={handleToToggleDropdown}
                          >
                            <div className="w-[3.84vh] h-[3.84vh] relative bg-white rounded-md border-2 border-white backdrop-blur-sm">
                              <div className=" absolute left-[.6vh] top-[.6vh] text-center text-stone-950   text-opacity-70 md:text-base text-sm font-semibold  leading-tight">
                                +8
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch  flex-col justify-start items-start gap-[.96vh] flex">
                        <div className="px-[1.92vh] justify-center items-start gap-[.96vh] inline-flex">
                          <div className=" dark:text-neutral-700 font-montserrat text-networkColor md:text-sm text-xs font-semibold  lowercase  tracking-wide">
                            my assets
                          </div>
                        </div>
                        <div className="self-stretch h-[22.7vh] px-[2.4vh] py-[1.44vh] rounded-[2.5vh] flex-col justify-cente items-center gap-[.96vh] flex  cursor-pointer ">
                          <ul
                            className="overflow-y-auto w-[100%]"
                            ref={ulRef}
                            onScroll={handleScroll}
                          >
                            {(toSearchQueryLifiTokens === ""
                              ? toVisibleData
                              : filterToTokensData
                            )?.map((item, index) => {
                              return (
                                <li key={index}>
                                  <div
                                    className="self-stretch w-full rounded-[2.5vh] bg- dark:bg- hover:bg-hoverColor dark:hover:bg-hoverDarkColor  py-[2.4vh] px-[2.4vh] justify-start items-center gap-[1.92vh] inline-flex"
                                    onClick={() => {
                                      setToTokenSelectLifi(item);
                                      setToSelectedData(!toSelectedData);
                                      setOverlayVisible(!overlayVisible);
                                      setToTokenIndexSelected(index);

                                      // console.log('logoURI', toChainData[toChainIndexSelected]?.logoURI)
                                    }}
                                  >
                                    <div className="grow shrink basis-0 h-[4.8vh] justify-start items-center gap-[1.96vh] flex">
                                      <div className="w-[4.8vh] h-[4.8vh] relative rounded-h-[4.8vh] shadow ">
                                        <div className="w-[4.8vh] h-[4.8vh] left-0 top-0 absolute  rounded-h-[4.8vh] border-stone-950 backdrop-blur-[5px]">
                                          <img
                                            className="w-[4.8vh] h-[4.8vh] left-[0.50px] top-[-0.50px] absolute rounded-[28.31vh]"
                                            src={item?.logoURI}
                                          />
                                        </div>
                                        <div className="w-[2.4vh] h-[2.4vh] left-[2.9vh] top-[3vh] absolute  rounded  border-neutral-50 ">
                                          {chainDataSelected?.logoURI && (
                                            <img
                                              className="w-[2.4vh] h-[2.4vh] left-[0.3vh] top-[0.3vh] border rounded absolute"
                                              src={chainDataSelected?.logoURI}
                                            />
                                          )}
                                        </div>
                                      </div>
                                      <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex">
                                        <div className="self-stretch justify-start items-center gap-[.96vh] inline-flex">
                                          <div className="text-center font-montserrat dark:text-neutral-50 text-fontPrimaryColor md:text-base text-sm font-semibold  -tight">
                                            {item?.symbol}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </li>
                              );
                            })}
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}

              {isToDropdownOpen && (
                <div className="w-[49.9vh] h-[56.9vh] relative dark:bg-stone-950 bg-white rounded-[2.4vh] shadow  border-zinc-500 border-opacity-60">
                  <div className="max-h-[56.9vh] left-0 top-0 absolute  flex-col justify-end items-center gap-[.96vh] inline-flex">
                    <div className="self-stretch h-[14vh] flex-col justify-end items-center flex">
                      <div className="self-stretch h-[8vh] pt-[1.44vh] pb-[.48vh] rounded-tl-[2.4vh] rounded-tr-[2.4vh]  flex-col justify-center items-center gap-[.96vh] flex">
                        <div className="w-[49.9vh] px-[2.4vh] py-[1.44vh] rounded-lg justify-center items-center gap-[.96vh] inline-flex">
                          <div className="grow shrink basis-0 h-[11vh] justify-between items-center flex">
                            <div className="grow shrink basis-0 h-[11vh] justify-start items-center gap-[1.44vh] flex">
                              <div className="flex-col justify-start items-start gap-[.48vh] inline-flex">
                                <div className="self-stretch justify-start items-center gap-[.96vh] inline-flex">
                                  <div className="text-center font-montserrat dark:text-neutral-50 md:text-base text-sm font-semibold  -tight">
                                    Select Network
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="w-[3.36vh] h-[3.36vh] relative">
                            <div
                              className="w-[3.36vh] h-[3.36vh] left-[0.01px] top-0 absolute cursor-pointer"
                              onClick={() => {
                                setIsToDropdownOpen(!isToDropdownOpen);
                              }}
                            >
                              <img
                                src="/icons/close-circle-dark.svg"
                                className="dark:visible invisible w-0 dark:w-[3.36vh]"
                              />
                              <img
                                src="/icons/close-circle2.svg"
                                className="dark:invisible visible dark:w-0"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="self-stretch h-[5.76vh] px-[1.44vh] justify-start items-center gap-[1.92vh] inline-flex">
                        <div className="grow shrink basis-0 h-[4vh] px-[1.44vh] py-[.96vh] dark:bg-zinc-900 bg-fontColorNftCardDark rounded-[3.6vh] justify-start items-center gap-[1.44vh] flex">
                          <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex">
                            <div className="self-stretch justify-between items-center inline-flex">
                              <input
                                type="text"
                                className="text-zinc-500 text-sm font-montserrat font-medium  -[16.9.6vh] w-full bg-transparent border-none focus:outline-none"
                                placeholder="Search network"
                                value={toSearchQueryLifiChains}
                                onChange={toHandleSearchChangeLifiChains}
                              />{" "}
                              <div className="w-[2.2vh] h-[2.2vh] relative">
                                <div className="w-[2.2vh] h-[2.2vh] left-0 top-0 absolute">
                                  <img src="/icons/search-normal.svg"></img>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="self-stretch flex-col justify-start items-center gap-[.92vh] flex overflow-y-auto">
                      {(toSearchQueryLifiChains === ""
                        ? toChainData
                        : filterToChainData
                      )?.map((item: any, index: any) => (
                        <div
                          className="self-stretch  flex-col justify-start items-start gap-[.96vh] flex cursor-pointer rounded-[2.5vh] bg-primaryColor dark:bg-primaryDarkColor hover:bg-hoverColor dark:hover:bg-hoverDarkColor"
                          onClick={() => {
                            setToChainIdSelected(item?.id);
                            setIsToDropdownOpen(!isToDropdownOpen);
                            setToChainIndexSelected(index);
                            setToChainDataSelected(item);
                          }}
                        >
                          <div className="self-stretch h-[8.6vh] px-[2.4vh] py-[1.44vh] rounded-[2.5vh] flex-col justify-center items-center gap-[.96vh] flex">
                            <div className="self-stretch justify-start items-center gap-[1.92vh] inline-flex">
                              <div className="grow shrink basis-0 h-[3.84vh] justify-start items-center gap-[1.44vh] flex">
                                <div className="justify-start items-center gap-[.12vh] flex">
                                  <div className="w-[3.84vh] h-[3.84vh] relative  border-white backdrop-blur-sm">
                                    <div className="w-[2.88vh] h-[2.88vh] left-[4.01vh] top-[.5vh] absolute  " />
                                    <img
                                      src={item?.logoURI}
                                      className="rounded border"
                                    />
                                  </div>
                                </div>
                                <div className="grow shrink basis-0 flex-col justify-start items-start gap-[.48vh] inline-flex">
                                  <div className="self-stretch justify-start items-center gap-[.96vh] inline-flex">
                                    <div className="text-center font-montserrat dark:text-neutral-50 text-fontPrimaryColor md:text-base text-sm font-semibold  -tight">
                                      {item?.name}
                                    </div>
                                  </div>
                                </div>
                                <div className="w-[3.84vh] h-[3.84vh] relative" />
                              </div>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}
            </div>
          )}
        </>
      )}
    </>
  );
};

export default ToSelectDropDown;
