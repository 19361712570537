import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { UserPrefState } from "./userpref.model";
import { CustomChainDataType, CustomTokenDataType } from "../../../types";

const INITIAL_STATE: UserPrefState = {};

export const userPrefSlice = createSlice({
  name: "userpref",
  initialState: INITIAL_STATE,
  reducers: {
    updateCurrentEditToken: (
      state,
      action: PayloadAction<CustomTokenDataType>
    ) => {
      state.currentEditToken = action.payload;
    },
    updateCurrentEditChain: (
      state,
      action: PayloadAction<CustomChainDataType>
    ) => {
      state.currentEditChain = action.payload;
    },
  },
});

export const { updateCurrentEditChain, updateCurrentEditToken } =
  userPrefSlice.actions;
