import { eventTypes, storageKeys } from "../utils/constants";
import { disconnect as disconnectAsync } from "../functions";
import { Config } from "@wagmi/core";
/**
 * For wallet icon disconnect functionality
 * @returns
 */
export function useDisconnect(): {
  disconnect: () => void;
  disconnectAsync: (config: Config) => Promise<void>;
} {
  const disconnect = () => {
    if (window.parent) {
      const message = JSON.stringify({
        type: eventTypes.disconnect,
        success: true,
      });
      window.parent.postMessage(message, document.referrer);
    }
    window.localStorage.removeItem(storageKeys.TRIA_WALLET_STORE);
    window.localStorage.removeItem(storageKeys.PERSIST_ROOT);
    const event = new Event(eventTypes?.disconnect);
    window.dispatchEvent(event);
    window.localStorage.removeItem("wagmi.connected");
    window.localStorage.removeItem("wagmi.tria");
    window.localStorage.removeItem("wagmi.store");
  };

  return { disconnect, disconnectAsync };
}
