import { Config, getAccount, disconnect as wagmiDisconnect } from "@wagmi/core";
import { storageKeys, triaAuthUrl } from "../utils/constants";
import { triaConnected, wagmiConnected } from "../utils/helper";

export const disconnect = async (
  config: Config,
  authOrigin: string = "https://connect.tria.so",
  walletOrigin: string = "https://web.tria.so"
): Promise<void> => {
  return new Promise<void>((resolve, reject) => {
    if (triaConnected()) {
      const embeddedWallet = document.querySelector("embedded-wallet");
      const iframe = embeddedWallet?.shadowRoot?.getElementById(
        "embedded-wallet-iframe"
      ) as HTMLIFrameElement | null;
      const walletIframe = document.getElementById(
        "embedded-wallet-iframe"
      ) as HTMLIFrameElement | null;

      let walletIframePresent = false;

      if (iframe || walletIframe) {
        walletIframePresent = true;
        (iframe || walletIframe)?.contentWindow?.postMessage(
          { type: "disconnect-called" },
          "*"
        );
      }

      const authIframe = document.getElementById(
        "auth-iframe"
      ) as HTMLIFrameElement | null;
      authIframe?.contentWindow?.postMessage(
        { type: "disconnect-called" },
        "*"
      );
      const transactionIframe = document.getElementById("transaction-iframe");
      if (transactionIframe) {
        transactionIframe.remove();
      }
      let authDisconnected = false;
      let walletDisconnected = !walletIframePresent;

      const handleDisconnectCallback = (event: MessageEvent) => {
        console.log("received disconnect event", window.location.origin, event);
        if (
          event.origin === authOrigin &&
          event.data.type === "disconnect-callback"
        ) {
          console.log("setting authDisconnected true");
          authDisconnected = true;
        } else if (
          walletIframePresent &&
          event.origin === walletOrigin &&
          event.data.type === "disconnect-callback"
        ) {
          console.log("setting walletDisconnected true");
          walletDisconnected = true;
        }
        console.log("values", authDisconnected, walletDisconnected);
        if (authDisconnected && walletDisconnected) {
          cleanup();
          resolve();
        }
      };

      const cleanup = () => {
        window.removeEventListener("message", handleDisconnectCallback);
        window.localStorage.removeItem(storageKeys.TRIA_WALLET_STORE);
      };

      window.addEventListener("message", handleDisconnectCallback);

      setTimeout(() => {
        cleanup();
        resolve();
      }, 5000);
    } else {
      resolve();
    }
  })
    .then(async () => {
      if (wagmiConnected()) {
        try {
          window.localStorage.removeItem("wagmi.connected");
          window.localStorage.removeItem("wagmi.tria");
          window.localStorage.removeItem("wagmi.store");

          await wagmiDisconnect(config);
        } catch (error) {
          console.error("Error during wagmi disconnect:", error);
          throw error;
        }
      }
    })
    .finally(() => {
      // window.location.reload();
    });
};
