import axios from "axios";
import { lifiUrl } from "@tria-sdk/constants";

export const getChains = async () => {
  try {
    // chainTypes can be of type SVM and EVM; by default, only EVM chains will be returned
    const optionalChainTypes = "EVM";
    const result = await axios.get(`${lifiUrl}/chains`, {
      params: { chainTypes: optionalChainTypes },
    });
    return result.data;
  } catch (error) {
    console.error(`Error in getChains: ${error}`);
    throw Error(`Error in getChains: ${error}`);
  }
};
