import { Account } from "../types";
import { TRIA_WALLET_STORE, isAaSupportedChain } from "./constants";

export const createEncodedData = (data: any) => {
  const encodedParams = btoa(JSON.stringify(data));
  return encodedParams;
};

export const getAccountFromWagmiStore = () => {
  const wagmiStore = window.localStorage.getItem("wagmi.store");
  const parsedStore = JSON.parse(wagmiStore as string);

  if (parsedStore?.state?.connections && parsedStore?.state?.current) {
    const currentKey = parsedStore.state.current;
    const connectionsMap = new Map(parsedStore.state.connections.value);
    const currentConnection = connectionsMap.get(currentKey) as any;
    return currentConnection?.accounts?.[0] || null;
  } else {
    return parsedStore?.state?.data?.account || null;
  }
};

export const getChainFromWagmiStore = () => {
  const wagmiStore = window.localStorage.getItem("wagmi.store");
  const parsedStore = JSON.parse(wagmiStore as string);
  if (parsedStore?.state?.chainId) {
    const chain = parsedStore?.state?.chainId;
    return chain;
  } else {
    return null;
  }
};
export const wagmiConnected = (): boolean => {
  const wagmiConnected = window.localStorage.getItem("wagmi.connected");
  if (wagmiConnected) return true;
  else return false;
};

export const wagmiConnectedAsync = async (): Promise<{
  isConnected: boolean;
  address: string;
}> => {
  const account = getAccountFromWagmiStore();

  return {
    isConnected: account.isConnected,
    address: account.address as string,
  };
};

export const triaConnected = (): boolean => {
  const value = window.localStorage.getItem("tria.wallet.store");
  if (value) return true;
  else return false;
};

export const getUserAddressByChain = (
  chainName: string,
  type: "EOA" | "AA"
) => {
  const triaStore = window.localStorage.getItem(TRIA_WALLET_STORE);
  const parsedStore = JSON.parse(triaStore as string) as Account;
  if (isAaSupportedChain(chainName) && type == "AA") {
    return parsedStore?.aa?.address;
  } else {
    return parsedStore?.evm?.address;
  }
};

// Global variables to store references
let iframeContainer: HTMLElement | null = null;
let minimizeButton: HTMLElement | null = null;
let isMinimized = false;
const hookName = "tria-iframe-container";

export const createIframe = (iframeUrl: string, calledFrom: string) => {
  // If iframe already exists, remove it
  if (iframeContainer) {
    document.body.removeChild(iframeContainer);
  }

  iframeContainer = document.createElement("div");
  iframeContainer.id = hookName;

  const positionString = localStorage.getItem("wallet.position");
  const position =
    positionString === "bottom-right" || positionString === "center"
      ? positionString
      : JSON.parse(positionString || "{}");

  const isSmallScreen = window.innerWidth <= 768; // Breakpoint for small screens, adjust as needed

  if (isSmallScreen) {
    // Full screen styles for mobile devices
    iframeContainer.style.cssText = `
      position: fixed;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 9999;
      overflow: hidden;
      transition: transform 0.5s cubic-bezier(0.19, 1, 0.22, 1);
      background-color: transparent;
    `;
  } else {
    // Desktop layout
    const bottomRight = `bottom: 16px; right: 16px;`;
    iframeContainer.style.cssText = `
      z-index: 9999;
      position: fixed;
      display: flex;
      justify-content: space-between;
      border-radius: 20px;
      overflow: hidden;
      ${
        position === "center"
          ? `top: 50%; left: 50%; transform: translate(-50%, -50%);`
          : typeof position === "object"
          ? `top: ${position.posY}px; left: ${position.posX}px;`
          : position === "bottom-right"
          ? bottomRight
          : ``
      }
    `;
  }

  const iframe = document.createElement("iframe");
  iframe.id = "transaction-iframe";

  if (isSmallScreen) {
    // Full screen styles for iframe on mobile
    iframe.style.cssText = `
      width: 100%;
      height: 100%;
      border: none;
      background-color: transparent;
    `;
  } else {
    // Desktop styles
    iframe.style.cssText = `
      height: 586px;
      width: 312px;
      border: none;
      border-radius: 20px;
      overflow: hidden;
    `;
  }

  iframe.src = iframeUrl;
  iframe.allow = "publickey-credentials-get; clipboard-write";

  // Append iframe to the container
  iframeContainer.appendChild(iframe);

  // Append iframeContainer to the document body
  document.body.appendChild(iframeContainer);

  // Add click listener for minimize button
};

// Independent control functions
export const closeIframe = () => {
  if (iframeContainer && iframeContainer.parentNode) {
    iframeContainer.parentNode.removeChild(iframeContainer);
    iframeContainer = null;
    minimizeButton = null;
    isMinimized = false;
  }
};

export const minimizeIframe = (height: number) => {
  if (iframeContainer) {
    const isSmallScreen = window.innerWidth <= 768;
    if (isSmallScreen) {
      // Set the height of the iframe
      iframeContainer.style.height = `${height}px`;

      // Calculate the translation
      const viewportHeight = window.innerHeight;
      const translationPercentage =
        ((viewportHeight - height) / viewportHeight) * 100;

      // Apply the transformation
      iframeContainer.style.transform = `translateY(${translationPercentage}%)`;
      isMinimized = true;
    }
  }
};

export const restoreIframe = () => {
  if (iframeContainer) {
    const isSmallScreen = window.innerWidth <= 768;
    if (isSmallScreen && isMinimized) {
      iframeContainer.style.height = `100vh`;
      iframeContainer.style.transform = "translateY(0)";
      if (minimizeButton) minimizeButton.textContent = "−";
      isMinimized = false;
    }
  }
};
