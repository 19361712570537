import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  NetworkItem,
  NonEvmAddressesType,
  RootState,
  copyToClipboard,
  formatAddress,
  useToast,
} from "../../../../rx.core";
import { CopyButton } from "../Buttons";
import { QrButton } from "../Buttons/QrButton";
import QR from "./QR";
import { uniq } from "lodash";
import { isEvmChain } from "@tria-sdk/core";

interface Props {
  addresses: string;
  activeChain: NetworkItem | undefined;
  nonEvmAddresses?: NonEvmAddressesType[];
  lite?: boolean;
  qrHandler?: (value: boolean) => void;
}

export const Accounts: React.FC<Props> = ({
  addresses,
  activeChain,
  nonEvmAddresses,
  lite = false,
  qrHandler,
}) => {
  const [showQr, setShowQr] = useState(false);
  const [qrAddress, setQrAddress] = useState("");
  const [qrChain, setQrChain] = useState<NetworkItem>();
  const [qrNetworkItems, setQrNetworkItems] = useState<NetworkItem[]>();
  const [qrLogo, setQrLogo] = useState("");
  const [copied, setIsCopied] = useState(false);
  const [copiedText, setCopiedText] = useState("");
  const { setToastData, openToastMessage, closeToastMessage } = useToast();
  const [expanded, setExpanded] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");

  const networkItems = useSelector(
    (store: RootState) => store.Constants?.NetworkItems
  );
  const aaNetworkItems = useSelector(
    (store: RootState) => store.Constants?.AANetworks
  );
  console.log("aa network items", aaNetworkItems);
  const userStatusOnChain = useSelector(
    (store: RootState) => store.User.userStatusOnChain
  );

  const allChains = [
    {
      // chainName: isEvmChain(activeChain?.chainName ?? "")
      //   ? activeChain?.chainName
      //   : "ETH",
      chainName: isEvmChain(activeChain?.chainName ?? "") ? "ETH" : "ETH",
      address: addresses,
    },
    ...(nonEvmAddresses || []),
  ];

  const filteredChains = allChains.filter((chain) =>
    chain?.chainName?.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const handleCopyClick = (item: string) => {
    setIsCopied(copyToClipboard(item)?.copied);
    setCopiedText(copyToClipboard(item)?.text || "");
  };

  useEffect(() => {
    if (copied) {
      setToastData({ title: "Copied!", desc: copiedText, status: "message" });
      openToastMessage();
      setIsCopied(false);
      setTimeout(() => closeToastMessage(), 4000);
    }
  }, [copied]);

  const handleQrClick = (
    address: string,
    qrChain?: NetworkItem,
    qrItems?: NetworkItem[],
    qrLogo?: string
  ) => {
    setShowQr(!showQr);
    setQrAddress(address);
    setQrChain(qrChain);
    setQrNetworkItems(qrItems);
    qrHandler && qrHandler(!showQr);
    if (qrLogo) setQrLogo(qrLogo);
  };

  const toggleExpand = () => {
    setExpanded(!expanded);
  };

  return (
    <div
      className={`min-w-full bg-primaryColor ${
        showQr ? "transform duration-200" : "h-[unset]"
      } rounded-[2vh] ${
        lite
          ? "dark:bg-popoverBackgroundDark"
          : "dark:bg-primaryDarkColor h-[53.5vh]"
      }`}
    >
      {!showQr && filteredChains.length > 3 && (
        <div className="px-[2vh] py-[1.4vh]">
          <div className="text-sm text-white w-full text-center justify-center mb-4 font-semibold">
            All Networks
          </div>
          <input
            type="text"
            placeholder="Search"
            className="text-white text-sm w-full h-[4vh] rounded-full px-3 mb-4 bg-primaryColor dark:bg-hoverDarkColor focus:outline-none focus:border focus:border-neutral-600"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
        </div>
      )}

      {!showQr && (
        <div
          className={`overflow-y-scroll max-h-[280px] w-full px-[2.4vh] pt-[2.4vh] flex-col justify-start gap-[1vh] inline-flex min-w-full relative`}
        >
          {filteredChains
            .slice(0, expanded ? filteredChains.length : 3)
            .map((chain, index) => (
              <div
                key={index}
                className="self-stretch justify-start items-center gap-[2vh] inline-flex mb-4"
              >
                <div className="flex gap-[0.5vh] flex-wrap">
                  {chain.chainName?.toUpperCase() === "AA"
                    ? uniq(aaNetworkItems.map((el) => el)).map(
                        (element, logoIndex) => {
                          if (element.chainName === "BINANCE")
                            return (
                              <img
                                key={logoIndex}
                                src={element.logo}
                                alt=""
                                className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
                              />
                            );
                        }
                      )
                    : // .slice(9, 10)
                      // ?.map((item: string, logoIndex: number) => (

                      //   <img
                      //     key={logoIndex}
                      //     src={item}
                      //     alt=""
                      //     className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
                      //   />
                      // ))
                      networkItems?.find(
                        (item) =>
                          chain.chainName?.toUpperCase() ===
                          item?.chainName?.toUpperCase()
                      ) && (
                        <img
                          src={
                            networkItems.find(
                              (item) =>
                                chain.chainName?.toUpperCase() ===
                                item?.chainName?.toUpperCase()
                            )?.logo
                          }
                          alt=""
                          className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
                        />
                      )}
                </div>
                <div className="grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex">
                  <div className="self-stretch justify-start items-center gap-[1vh] inline-flex">
                    <div
                      className={`${
                        lite ? "text-sm" : "text-[2vh]"
                      } text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 font-semibold leading-tight`}
                    >
                      {chain.chainName === "aa"
                        ? "Smart Wallet"
                        : chain.chainName}
                    </div>
                  </div>
                  <div
                    className={`${
                      lite ? "text-xs" : "text-[1.67vh]"
                    } text-center text-neutral-400 font-semibold leading-tight max-w-[24vh] overflow-hidden`}
                  >
                    {formatAddress(chain.address)}
                  </div>
                </div>
                <div
                  className="w-[2.1vh] h-[2.1vh] justify-center items-center flex cursor-pointer"
                  onClick={() =>
                    handleQrClick(
                      chain.address,
                      networkItems.find(
                        (item) => item.chainName === chain.chainName
                      )
                    )
                  }
                >
                  <QrButton />
                </div>
                <CopyButton
                  onClick={() => handleCopyClick(chain.address)}
                  height="2.1vh"
                  width="2.1vh"
                />
              </div>
            ))}
          {filteredChains.length > 3 && (
            <div
              className="text-sm w-full flex justify-center items-center px-[2vh] py-3 text-white/80 underline cursor-pointer"
              onClick={toggleExpand}
            >
              {expanded ? "Show less" : "Show more"}
            </div>
          )}
          {copied && (
            <div className="absolute bg-primaryColor dark:bg-primaryDarkColor text-fontLightColor dark:text-fontLightColorDark flex items-center justify-center rounded-[25px] w-[8.3vh] h-[2.8vh] bottom-[-1.2vh] right-[1.2vh] text-[1.67vh]">
              Copied
            </div>
          )}
        </div>
      )}
      {showQr && (
        <div
          className={`${
            lite ? "dark:bg-popoverBackgroundDark" : "dark:bg-primaryDarkColor"
          } shadow-2xl  flex bg-primaryColor dark:bg-popoverBackgroundDark rounded-[2vh] z-50 ${
            showQr ? "" : "hidden"
          }`}
        >
          <QR
            item={qrAddress}
            activeChain={qrChain}
            networkItems={qrNetworkItems || []}
            logo={qrLogo}
            liteMode={lite}
          />
        </div>
      )}
    </div>
  );
};

export default Accounts;

// import React, { useEffect, useState } from "react";
// import { useSelector } from "react-redux";
// import {
//   NetworkItem,
//   NonEvmAddressesType,
//   RootState,
//   copyToClipboard,
//   formatAddress,
//   useToast,
// } from "../../../../rx.core";
// import { CopyButton } from "../Buttons";
// import { QrButton } from "../Buttons/QrButton";
// import QR from "./QR";
// import { uniq } from "lodash";
// import { isEvmChain } from "@tria-sdk/core";

// interface Props {
//   addresses: string;
//   activeChain: NetworkItem | undefined;
//   nonEvmAddresses?: NonEvmAddressesType[];
//   lite?: boolean;
// }

// export const Accounts: React.FC<Props> = ({
//   addresses,
//   activeChain,
//   nonEvmAddresses,
//   lite = false,
// }) => {
//   const [showQr, setShowQr] = useState(false);
//   const [qrAddress, setQrAddress] = useState("");
//   const [qrChain, setQrChain] = useState<NetworkItem>();
//   const [qrNetworkItems, setQrNetworkItems] = useState<NetworkItem[]>();
//   const [qrLogo, setQrLogo] = useState("");
//   const [copied, setIsCopied] = useState(false);
//   const [copiedText, setCopiedText] = useState("");
//   const { setToastData, openToastMessage, closeToastMessage } = useToast();
//   const [expanded, setExpanded] = useState(false);

//   const networkItems = useSelector(
//     (store: RootState) => store.Constants?.NetworkItems
//   );
//   const aaNetworkItems = useSelector(
//     (store: RootState) => store.Constants?.AANetworks
//   );
//   const userStatusOnChain = useSelector(
//     (store: RootState) => store.User.userStatusOnChain
//   );

//   const handleCopyClick = (item: string) => {
//     setIsCopied(copyToClipboard(item)?.copied);
//     setCopiedText(copyToClipboard(item)?.text || "");
//   };

//   useEffect(() => {
//     if (copied) {
//       setToastData({ title: "Copied!", desc: copiedText, status: "message" });
//       openToastMessage();
//       setIsCopied(false);
//       setTimeout(() => closeToastMessage(), 4000);
//     }
//   }, [copied]);

//   const handleQrClick = (
//     address: string,
//     qrChain?: NetworkItem,
//     qrItems?: NetworkItem[],
//     qrLogo?: string
//   ) => {
//     setShowQr(!showQr);
//     setQrAddress(address);
//     setQrChain(qrChain);
//     setQrNetworkItems(qrItems);
//     if (qrLogo) setQrLogo(qrLogo);
//   };
//   return (
//     <div
//       className={`min-w-full bg-primaryColor  ${
//         showQr ? "transform duration-200" : "h-[unset]"
//       } rounded-[2vh] ${
//         lite
//           ? "dark:bg-popoverBackgroundDark"
//           : "dark:bg-primaryDarkColor h-[53.5vh] "
//       }`}
//     >
//       <div className="px-[2vh] py-[1.4vh]">
//         <div className="text-sm text-white w-full text-center justify-center mb-4 font-semibold">
//           All Networks
//         </div>
//         <input
//           type="text"
//           placeholder="Search"
//           className="text-sm w-full h-[4vh] rounded-full px-3 mb-4 bg-primaryColor dark:bg-hoverDarkColor focus:outline-none focus:border focus:border-neutral-600"
//         />
//       </div>
//       {!showQr && (
//         <div className="w-full px-[2.4vh] py-[1.4vh] flex-col justify-start gap-[1vh] inline-flex min-w-full relative">
//           <div className="self-stretch justify-start items-center gap-[2vh] inline-flex">
//             <div className="grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex">
//               <div className="self-stretch justify-start items-center gap-[1vh] inline-flex">
//                 <div
//                   className={`${
//                     lite ? "text-sm" : "text-[2vh]"
//                   } text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80  font-semibold  leading-tight`}
//                 >
//                   {isEvmChain(activeChain?.chainName ?? "")
//                     ? activeChain?.chainName
//                     : "ETH"}
//                 </div>
//               </div>
//               <div
//                 className={`${
//                   lite ? "text-xs" : "text-[1.67vh]"
//                 } text-center text-neutral-400  font-semibold leading-tight max-w-[24vh] overflow-hidden`}
//               >
//                 {formatAddress(addresses)}
//               </div>
//             </div>
//             <div
//               className="w-[2.1vh] h-[2.1vh] justify-center items-center flex cursor-pointer"
//               onClick={() =>
//                 handleQrClick(addresses, activeChain, networkItems)
//               }
//             >
//               <QrButton />
//             </div>
//             <CopyButton
//               onClick={() => handleCopyClick(addresses)}
//               height="2.1vh"
//               width="2.1vh"
//             />
//           </div>
//           {!lite && (
//             <div className="flex gap-[0.5vh]">
//               {uniq(networkItems.map((el) => el.logo))
//                 ?.slice(0, 9)
//                 ?.map((item: string, index: number) => (
//                   <div key={index} className="">
//                     <img
//                       src={item}
//                       alt=""
//                       className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
//                     />
//                   </div>
//                 ))}
//             </div>
//           )}

//           {copied && (
//             <div className="absolute bg-primaryColor dark:bg-primaryDarkColor text-fontLightColor dark:text-fontLightColorDark flex items-center justify-center rounded-[25px] w-[8.3vh] h-[2.8vh] bottom-[-1.2vh] right-[1.2vh] text-[1.67vh]">
//               Copied
//             </div>
//           )}
//         </div>
//       )}
//       {!showQr &&
//         nonEvmAddresses?.map((nonevmaddress) => (
//           <div
//             className={`w-full px-[2.4vh] py-[1.4vh] flex-col justify-start gap-[1vh] inline-flex min-w-full  relative ${
//               userStatusOnChain?.type !== "AA" ? "opacity-40" : ""
//             }`}
//           >
//             <div className="self-stretch justify-start items-center gap-[2vh] inline-flex">
//               <div className="grow shrink basis-0 flex-col justify-start items-start gap-[0.5vh] inline-flex">
//                 <div className="self-stretch justify-start items-center gap-[0.5vh] inline-flex">
//                   {nonevmaddress?.chainName?.toUpperCase() === "AA" ? (
//                     <div
//                       className={`${
//                         lite ? "text-sm" : "text-[2vh]"
//                       } text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 font-semibold  leading-tight flex items-center justify-center gap-[0.3vh]`}
//                     >
//                       <p>{"Smart Wallet"}</p>

//                       <img
//                         src="./images/aaverifiedbatch.svg"
//                         alt="AA"
//                         className="w-[3.4vh] h-[3.4vh]"
//                       />
//                     </div>
//                   ) : (
//                     <div
//                       className={`${
//                         lite ? "text-sm" : "text-[2vh]"
//                       } text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80  font-semibold  leading-tight`}
//                     >
//                       {nonevmaddress?.chainName}
//                     </div>
//                   )}
//                 </div>
//                 {nonevmaddress?.address && (
//                   <div
//                     className={`${
//                       lite ? "text-xs" : "text-[1.67vh]"
//                     } text-center text-neutral-400  font-semibold leading-tight max-w-[24vh] overflow-hidden mt-[-0.8vh]`}
//                   >
//                     {formatAddress(nonevmaddress?.address)}
//                   </div>
//                 )}
//               </div>
//               <div
//                 className="w-[2.1vh] h-[2.1vh] justify-center items-center flex cursor-pointer"
//                 onClick={() =>
//                   handleQrClick(
//                     nonevmaddress?.address,
//                     networkItems?.find(
//                       (item) =>
//                         nonevmaddress?.chainName?.toUpperCase() ===
//                         item?.chainName?.toUpperCase()
//                     ),
//                     undefined,
//                     networkItems.find(
//                       (item) =>
//                         nonevmaddress?.chainName?.toUpperCase() ===
//                         item?.chainName?.toUpperCase()
//                     )?.logo
//                   )
//                 }
//               >
//                 <QrButton />
//               </div>
//               {userStatusOnChain?.type === "AA" ? (
//                 <CopyButton
//                   onClick={() => handleCopyClick(nonevmaddress?.address)}
//                   height="2.1vh"
//                   width="2.1vh"
//                 />
//               ) : (
//                 <CopyButton
//                   onClick={() => {
//                     setToastData({
//                       title: "Current Chain does not Support Smart Wallet!",
//                       desc: "Current Chain does not Support Smart Wallet",
//                       status: "error",
//                     });
//                     openToastMessage();
//                     setTimeout(() => closeToastMessage(), 4000);
//                   }}
//                   height="2.1vh"
//                   width="2.1vh"
//                 />
//               )}
//             </div>
//             {copied && (
//               <div className="absolute bg-primaryColor dark:bg-primaryDarkColor text-fontLightColor dark:text-fontLightColorDark flex items-center justify-center rounded-[25px] w-[8.3vh] h-[2.8vh] bottom-[-1.2vh] right-[1.2vh] text-[1.67vh]">
//                 Copied
//               </div>
//             )}

//             {!lite && (
//               <>
//                 {nonevmaddress?.chainName?.toUpperCase() === "AA" ? (
//                   <div className="flex gap-[0.5vh]">
//                     {uniq(aaNetworkItems.map((el) => el.logo))
//                       .slice(0, 9)
//                       ?.map((item: string, index: number) => (
//                         <img
//                           src={item}
//                           alt=""
//                           className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
//                           key={index}
//                         />
//                       ))}
//                   </div>
//                 ) : (
//                   <div className="flex gap-[0.5vh]">
//                     {networkItems?.find(
//                       (item) =>
//                         nonevmaddress?.chainName?.toUpperCase() ===
//                         item?.chainName?.toUpperCase()
//                     ) && (
//                       <img
//                         src={
//                           networkItems.find(
//                             (item) =>
//                               nonevmaddress?.chainName?.toUpperCase() ===
//                               item?.chainName?.toUpperCase()
//                           )?.logo
//                         }
//                         alt=""
//                         className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
//                       />
//                     )}
//                   </div>
//                 )}
//               </>
//             )}
//           </div>
//         ))}
//       {showQr && (
//         <div
//           className={` shadow-2xl absolute  flex bg-primaryColor dark:bg-primaryDarkColor rounded-[2vh]  z-50 ${
//             showQr ? "" : "hidden"
//           }`}
//         >
//           <QR
//             item={qrAddress}
//             activeChain={qrChain}
//             networkItems={qrNetworkItems || []}
//             logo={qrLogo}
//           />
//         </div>
//       )}
//       {/*  */}
//       <div className="text-sm w-full flex justify-center items-center px-[2vh] py-3 text-white/80 underline cursor-pointer">
//         Show more
//       </div>
//     </div>
//   );
// };

// export default Accounts;
