import React, { useState } from "react";
import { QrGenerator } from "./QrGenerator";
import { NetworkItem, formatAddress, useToast } from "../../../../rx.core";

interface Props {
  item: string | undefined;
  activeChain: NetworkItem | undefined;
  networkItems: NetworkItem[];
  logo?: string;
  liteMode?: boolean;
}

const QR: React.FC<Props> = ({
  item,
  activeChain,
  networkItems,
  logo,
  liteMode,
}) => {
  const [copied, setIsCopied] = useState(false);
  const { setToastData, openToastMessage, closeToastMessage } = useToast();
  const copyToClipboard = (text: string | undefined) => {
    if (text) {
      setToastData({ title: "Copied!", desc: text, status: "message" });
      openToastMessage();
      setTimeout(() => {
        closeToastMessage();
      }, 800);
      setIsCopied(false);
      navigator.clipboard
        .writeText(text)
        .then(() => {
          setTimeout(() => setIsCopied(true), 200);
        })
        .catch((error) => {
          console.error("Failed to copy: ", error);
        });
    }
    setTimeout(() => setIsCopied(false), 2000);
  };
  const handleCopyClick = () => {
    copyToClipboard(item);
  };

  return (
    <div
      className={`${
        liteMode ? "" : "w-[40.5vh]"
      } flex-col justify-start items-center flex rounded-lg py-4 px-6`}
    >
      {activeChain?.chainName === "SOLANA" && (
        <QrGenerator content={item} logo="/images/solanalogo.svg" />
      )}
      {activeChain?.chainName !== "SOLANA" && <QrGenerator content={item} />}
      <div
        className={`${
          liteMode
            ? "dark:bg-popoverBackgroundDark "
            : "dark:bg-primaryDarkColor"
        } px-[2.4vh] pt-3 bg-primaryColor flex-col justify-start items-center gap-[1vh] inline-flex relative rounded-b-lg`}
      >
        <div className="self-stretch gap-2 flex flex-col justify-center items-center">
          <div className="grow shrink basis-0 flex-col justify-center items-center inline-flex">
            <div className="self-stretch justify-start items-center gap-[1vh] inline-flex">
              <div className="text-center text-fontPrimaryColor dark:text-fontPrimaryDarkColor text-opacity-80 text-sm font-semibold  leading-tight">
                <span>Your </span>
                <span>{activeChain?.chainName.toLowerCase()} </span>
                <span>address</span>
              </div>
            </div>
            <div className="text-center text-neutral-400 text-xs font-semibold max-w-[24vh] overflow-hidden leading-tight">
              {formatAddress(item || "")}
            </div>
          </div>
          <div className=" bg-hoverDarkColor dark:bg-white rounded-[2.9vh] justify-center items-center flex cursor-pointer">
            <div
              className="px-6 py-2 self-stretch justify-center items-center gap-[1vh] flex"
              onClick={handleCopyClick}
            >
              {!copied && (
                <div className="text-center text-black text-xs font-semibold  leading-tight">
                  copy
                </div>
              )}
              {copied && (
                <div className="text-center text-black text-xs font-semibold leading-tight">
                  copied
                </div>
              )}
            </div>
          </div>
        </div>
        <div className="flex gap-[0.2vh] absolute left-[2.4vh] top-[7.1vh]">
          {networkItems &&
            networkItems?.slice(0, 9)?.map((item, index) => (
              <div key={index} className="">
                <img
                  src={item?.logo}
                  alt=""
                  className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
                />
              </div>
            ))}
          {logo && (
            <div className="">
              <img
                src={logo}
                alt=""
                className="w-[2.8vh] h-[2.8vh] rounded-[0.6vh]"
              />
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default QR;
