import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AssetForTriaName, RootState } from "../package/rx.core";
import {
  updateRefreshLoad,
  updateUserAssets,
  updateUserAssetsLastFetch,
} from "../package/rx.core/src/redux/features";
import { useReload } from "../package/ui.common/src/contexts/reload/ReloadContext";
import { useTriaUser } from "../package/ui.common/src/contexts/tria-user-provider/useTriaUser";
import { useTriaName } from "./useTriaName";
import { useUserPrefs } from "./useFetchUserPrefs";

export const useFetchAssets = () => {
  const dispatch = useDispatch();
  const { getAssetsForATriaName, getAssetDetails } = useTriaUser();
  const [isLoading, setIsLoading] = useState<boolean>(true); // Start with true
  const [isInitialLoad, setIsInitialLoad] = useState<boolean>(true);

  const lastFetchTime = useSelector(
    (store: RootState) => store?.User?.userAssetsLastFetch
  );
  const assets = useSelector((store: RootState) => store.User?.userAssets);

  const assetsDataExists = useSelector(
    (store: RootState) => store.User?.userAssets
  );
  const userInactive = useSelector(
    (state: RootState) => state.User.isUserInactive
  );
  const refreshLoading = useSelector(
    (store: RootState) => store?.AppState?.AppCurrentState?.refreshLoading
  );
  const { reload } = useReload();
  const { data: userPrefs } = useUserPrefs();

  const { triaName } = useTriaName();

  const fetchAssetsForATriaName = useCallback(async () => {
    const now = new Date();
    if (!userInactive) {
      const updatedNeeded =
        now.getTime() - new Date(lastFetchTime).getTime() >= 10000;
      console.log("user inactive : fetch assets", userInactive);
      if (!lastFetchTime || updatedNeeded || reload) {
        try {
          if (isInitialLoad || reload) {
            setIsLoading(true);
          }
          const valpromise = getAssetsForATriaName(triaName || "");

          if (userPrefs?.customChains && userPrefs?.customTokens) {
            const customChainDetails = (
              await Promise.all(
                userPrefs.customChains.map(async (chain) => {
                  const tokensForChain = userPrefs.customTokens.filter(
                    (token) => token.chainId === chain.chainId
                  );
                  const accessToken = localStorage.getItem("tria.accessToken");
                  const tokenDetails = await Promise.all(
                    tokensForChain.map((token) =>
                      getAssetDetails({
                        tokenAddress: token.tokenAddress,
                        chain,
                        triaName,
                        type: "EOA",
                        accessToken: accessToken || undefined,
                      })
                    )
                  );

                  return tokenDetails;
                })
              )
            )
              .flat()
              .map((el) => el.data)
              .filter((el) => !!el);

            // Transform AssetDetail to AssetForTriaName
            const transformedCustomAssets: AssetForTriaName[] =
              customChainDetails.map((customAsset) => ({
                wallet: customAsset!.wallet,
                name: customAsset!.name,
                symbol: customAsset!.symbol,
                logoUrl: customAsset!.logoUrl,
                chainName: customAsset!.chainName,
                chainLogo: customAsset!.chainLogo,
                balanceInTokens: customAsset!.balanceInTokens,
                balanceOfTokensInUnits: customAsset!.balanceOfTokensInUnits,
                decimals: customAsset!.decimals,
                balanceInUSD: customAsset!.balanceInUSD,
                quoteRate: customAsset!.quoteRate,
                tokenAddress: customAsset!.tokenAddress,
                isNativeToken: customAsset!.isNativeToken,
                isSpam: customAsset!.isSpam,
                percentChangein24hr: customAsset!.percentChangein24hr,
                isCustomToken: true,
              }));
            const val = await Promise.resolve(valpromise);
            const assets = val as AssetForTriaName[];
            // Combine assets and transformedCustomAssets into a single array
            const combinedAssets = [...assets, ...transformedCustomAssets];

            dispatch(updateUserAssets(combinedAssets));
          } else {
            const val = await Promise.resolve(valpromise);
            const assets = val as AssetForTriaName[];
            dispatch(updateUserAssets(assets));
          }

          dispatch(updateUserAssetsLastFetch(new Date().toISOString()));
        } catch (error: any) {
          setIsLoading(false);
          if (isInitialLoad || reload) {
            setIsInitialLoad(false);
          }
          console.error(error);
          if (isInitialLoad || reload) {
            setIsInitialLoad(false);
          }
        } finally {
          setIsLoading(false);
          if (isInitialLoad || reload) {
            setIsInitialLoad(false);
          }
          dispatch(updateRefreshLoad(false));
          if (isInitialLoad || reload) {
            setIsInitialLoad(false);
          }
        }
      }
    }
  }, [
    dispatch,
    getAssetDetails,
    getAssetsForATriaName,
    isInitialLoad,
    lastFetchTime,
    reload,
    triaName,
    userInactive,
    userPrefs,
  ]);

  useEffect(() => {
    if (triaName) {
      fetchAssetsForATriaName();

      const intervalId = setInterval(fetchAssetsForATriaName, 10000);

      return () => {
        clearInterval(intervalId);
      };
    }
  }, [
    triaName,
    dispatch,
    assetsDataExists,
    userInactive,
    fetchAssetsForATriaName,
  ]);

  useEffect(() => {
    if (reload) {
      fetchAssetsForATriaName();
      setIsLoading(true);
    }
  }, [fetchAssetsForATriaName, reload]);

  useEffect(() => {
    if (!refreshLoading && isLoading) {
      console.log("refresh again");
      dispatch(updateRefreshLoad(true));
    }
  }, [refreshLoading, isLoading, dispatch]);

  return isLoading;
};
