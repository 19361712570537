import { Dispatch, SetStateAction, useEffect, useRef, useState } from "react";
import { useFetchAssets, useFetchNfts } from "../../../../../../hooks";
import { useDispatch, useSelector } from "react-redux";
import {
  RootState,
  AssetForTriaName,
  updateTokenInfo,
  updateNftDetails,
  TokenInfo,
  updateFetchParams,
} from "../../../../../rx.core";
import { AssetContainer } from "./AssetContainer";
import { NftCard } from "../../Cards/NftCard";
import { useNavigate } from "react-router-dom";
import { BuyCryptoSearch } from "./BuyCryptoSearch";

interface Props {
  expanded: boolean;
  onClick: () => void;
  liteMode?: boolean;
}

export const AssetSuggestionCard: React.FC<Props> = ({
  expanded,
  onClick: handleExpanded,
  liteMode,
}) => {
  const [suggestionShow, setSuggestionShow] = useState(true);
  const [showCrypto, setShowCrypto] = useState(true);
  const nftItems = useSelector((store: RootState) => store.User?.userNfts);
  const userCrypto = useSelector((store: RootState) => store.User?.userAssets);
  const navigate = useNavigate();
  const tokenLoading = useFetchAssets();
  const nftLoading = useFetchNfts();
  const dispatch = useDispatch();
  // const handleToggle = () => {
  //   setSuggestionShow(!suggestionShow)
  // }
  useEffect(() => {
    setSuggestionShow(expanded);
  }, [expanded]);

  const handleNftItemClick = (index: number) => {
    const selected = nftItems[index];
    if (selected) {
      if (liteMode) {
        navigate("/home/sendCrypto/nftsend?lite=true", { state: { selected } });
      } else {
        navigate("/home/sendCrypto/nftsend", { state: { selected } });
      }

      dispatch(
        updateNftDetails({
          nftImage: selected?.nftInfo?.content?.preview?.url,
          nftName: selected?.nftInfo?.name,
          type: selected?.nftInfo?.interface,
          tokenAddress: selected?.nftInfo?.contract_address,
          tokenId: selected?.nftInfo?.token_id,
          amount: selected?.amount,
          chainName: selected?.chainName,
          chainLogo: selected?.chainLogo,
          senderAddress: selected?.wallet?.address,
          addressType: selected?.wallet?.type,
        })
      );
    }
  };

  const handleTokenItemClick = (cryptoItem: AssetForTriaName) => {
    const payload: TokenInfo = {
      qouteRate: cryptoItem?.quoteRate,
      senderBalance: cryptoItem?.balanceInTokens,
      chainName: cryptoItem?.chainName,
      chainLogo: cryptoItem?.chainLogo,
      tokenName: cryptoItem?.symbol,
      tokenLogo: cryptoItem?.logoUrl,
      tokenAddress: cryptoItem?.tokenAddress || "",
      senderAddress: cryptoItem?.wallet?.address,
      type: cryptoItem?.wallet?.type,
      isNativeToken: cryptoItem?.isNativeToken,
      isCustomToken: cryptoItem?.isCustomToken,
    };
    dispatch(
      updateFetchParams({
        chainName: cryptoItem?.chainName,
        tokenAddress: cryptoItem?.tokenAddress?.toString(),
        isCustomToken: cryptoItem?.isCustomToken,
      })
    );
    dispatch(updateTokenInfo(payload));
    if (liteMode) {
      navigate("/home/sendCrypto/cryptoSendPage?lite=true");
    } else {
      navigate("/home/sendCrypto/cryptoSendPage");
    }
  };

  return (
    <div
      className={`self-stretch z-50 flex-col justify-start items-center gap-[1vh] rounded-t-[2.4vh] border-t-[0.08vh] border-[#202020] flex ${
        suggestionShow
          ? `h-[59.5vh] transform duration-300`
          : `transform duration-300 h-[13.1vh]`
      }`}
    >
      <div className=" self-stretch h-[59.5vh] bg-primaryColor dark:bg-primaryDarkColor rounded-t-[20px]  flex-col justify-end items-center gap-[1vh] flex ">
        <div className="self-stretch h-[8vh] py-[1.4vh] bg-primaryColor dark:bg-primaryDarkColor rounded-t-[2.4vh]  flex-col justify-center  gap-[1vh] flex">
          <div className="h-[5.1vh] px-[2.4vh] py-[1.4vh] rounded-[1vh] flex-col justify-center items-center gap-[1vh] flex">
            <div className="self-stretch justify-between items-center flex ">
              <div className="justify-start items-center gap-[1.4vh] flex z-[50] h-full ">
                <div className="flex-col justify-start items-start gap-[0.5vh] inline-flex">
                  <div
                    className="self-stretch justify-start items-center gap-[1vh] inline-flex"
                    onClick={() => {
                      setShowCrypto(true);
                    }}
                  >
                    {userCrypto.length ? (
                      <div
                        className={`text-center ${
                          !showCrypto
                            ? "text-fontLightColor dark:text-fontLightColorDark"
                            : "text-fontPrimaryColor dark:text-fontPrimaryDarkColor"
                        } text-opacity-80 text-[2vh] font-semibold leading-tight cursor-pointer`}
                      >
                        Crypto
                      </div>
                    ) : (
                      <div
                        className={`text-center ${
                          !showCrypto
                            ? "text-fontLightColor dark:text-fontLightColorDark"
                            : "text-fontPrimaryColor dark:text-fontPrimaryDarkColor"
                        } text-opacity-80 text-[2vh] font-semibold leading-tight cursor-pointer`}
                      >
                        Buy Crypto
                      </div>
                    )}
                  </div>
                </div>
                <div
                  className="flex-col justify-start items-start gap-[0.5vh] inline-flex"
                  onClick={() => {
                    setShowCrypto(false);
                  }}
                >
                  <div className="self-stretch justify-start items-center gap-[0.5vh] inline-flex">
                    <div
                      className={`text-center ${
                        showCrypto
                          ? "text-fontLightColor dark:text-fontLightColorDark"
                          : "text-fontPrimaryColor dark:text-fontPrimaryDarkColor"
                      }  text-[2vh] font-semibold font-'Montserrat' leading-tight cursor-pointer`}
                    >
                      NFTs
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="w-[2.1vh] h-[2.1vh] justify-end items-center flex cursor-pointer z-50"
                onClick={handleExpanded}
              >
                <div
                  className={`w-[2.1vh] h-[2.1vh] relative ${
                    suggestionShow ? "" : "transform rotate-180 duration-100"
                  }`}
                >
                  <img
                    src="/icons/arrow-down.png"
                    className="visible w-[2.1vh] dark:invisible dark:w-0"
                  ></img>
                  <img
                    src="/icons/arrow-down-dark.svg"
                    className="invisible w-0 dark:visible dark:w-[2.1vh]"
                  ></img>
                </div>
              </div>
            </div>
          </div>
        </div>
        {showCrypto && (
          <div className="min-h-[39.3vh] h-[51vh] flex justify-center items-start self-stretch overflow-hidden">
            {!userCrypto.length ? (
              <div className="">
                <BuyCryptoSearch />
              </div>
            ) : (
              <div className="self-stretch w-full h-[51vh] flex-col justify-start items-center gap-[1vh] flex overflow-y-scroll mb-[10vh]">
                {userCrypto.map((crypto, index) => (
                  <AssetContainer
                    cryptoItem={crypto}
                    onClick={handleTokenItemClick}
                  />
                ))}
              </div>
            )}
          </div>
        )}
        {!showCrypto && (
          <div className="w-full min-h-[39.3vh] h-[51vh] self-stretch grid grid-cols-2 px-[3.6vh] gap-[1.4vh] overflow-auto">
            {nftItems.length > 0 &&
              nftItems?.map((nftItem, index) => (
                <NftCard
                  key={index}
                  id={index}
                  // loading={loading}
                  nftItem={nftItem}
                  onClick={handleNftItemClick}
                />
              ))}
            {nftItems.length == 0 && (
              <div className="flex flex-col gap-[1vh] items-center justify-center w-[48vh] h-[38vh]">
                <img
                  src="/images/nonftimagedark.svg"
                  className="w-[17.8vh] h-[17.8vh]"
                />
                <p className="font-[600] text-[1.4vh] text-fontLightColor dark:text-fontLightColorDark">
                  You have no NFTs right now
                </p>
              </div>
            )}
          </div>
        )}
      </div>
    </div>
  );
};
