export interface TriaWalletStore {
  triaName?: string;
  darkMode?: boolean;
  appDomain?: string;
  fromDapp?: boolean;
  primaryColor?: string;
  defaultChain?: string;
  addresses?: Addresses;
  logo?: string;
  supportedChains?: unknown[];
  userAddress?: string;
  liteMode?: boolean;
  embedded?: boolean;
}

interface Addresses {
  triaName: string;
  evm: Evm;
  nonEvm: NonEvm[];
  aa: Aa;
}

interface Evm {
  address: string;
}

interface NonEvm {
  chainName: string;
  address: string;
}

interface Aa {
  address: string;
  accountType: string;
}

export const getWalletStoreLocalStorage = (): TriaWalletStore | null => {
  try {
    const data = localStorage.getItem("tria.wallet.store.app");
    return data ? JSON.parse(data) : null;
  } catch {
    return null;
  }
};
